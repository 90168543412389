import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { CHANGE, MARKET_CAP, PRICE, VOLUME } from 'src/app/utils/constant';
import { WatchListCreateAddModalComponent } from '../../tool-dashboard/watchlist-create-add-modal.component';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup-scanner-data-listing',
  templateUrl: './setup-scanner-data-listing.component.html',
  styleUrls: ['./setup-scanner-data-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupScannerDataListingComponent implements OnInit {
  mode: string;
  watchListRow: any[] = [];
  lookupListVisibility: any;
  searchResult: any;
  showDatatable: boolean;
  isDropdownOpen = false;
  rows: any;
  public tag = [];
  public page = new Page();
  public offset: number;
  public isToolAccess = false;
  public lockedPageContent: null;
  selectedMarketCap: string;
  selectedPrice: string;
  selectedChange: string;
  selectedVolume: string;
  getSetupScannerData: string;
  chunkedData = [];
  MARKET_CAP = MARKET_CAP;
  PRICE = PRICE;
  CHANGE = CHANGE;
  VOLUME = VOLUME;
  filterDataObject = {};
  filterCountShow = 0;
  isLoading = true;

  private closeMenuSubs: Subscription;
  private isMenuClosed: boolean = false;
  staticData = [
    {
      name: 'Weekly Option Stocks',
      description: 'It refer to stocks that have options contracts with weekly expiration dates.',
      image: '/assets/icon/Rectangle 31.png',
    },
    {
      name: 'Surprise Factor',
      description:
        'A metric used to gauge the extent to which actual earnings or economic factor differ from market expectations',
      image: '/assets/icon/Rectangle 30.png',
    },
    {
      name: 'Crowbar',
      description:
        'Technical analysis pattern indicating a strong, sudden price movement breaking through resistance or support levels.',
      image: '/assets/icon/Rectangle 31 (1).png',
    },
    {
      name: 'Sunrise/Demise',
      description:
        'The Sunrise pattern indicates a potential bullish reversal, while the Demise pattern signals a potential bearish reversal.',
      image: '/assets/icon/Rectangle 31 (2).png',
    },
    {
      name: 'Stocks on the move',
      description: 'Stocks experiencing significant price changes or trading volume spikes.',
      image: '/assets/icon/Rectangle 32.png',
    },
    {
      name: 'Ideal setup',
      description:
        'A trading condition that aligns perfectly with a traders strategy for optimal potential profit',
      image: '/assets/icon/Rectangle 32 (1).png',
    },
  ];
  constructor(
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    private activatedRoute: ActivatedRoute,
    private storageServ: StorageService,
    public loaderService: LoaderService,
    private router: Router,
    private toastServ: ToastService,
    private toolsApi: ToolServiceService,
    public modalController: ModalController
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }
  role: any;
  tool: any;
  [x: string]: any;
  sortDirection = 'asc';
  public hideCryptoDetails: boolean = false;
  public toolTitle: string;
  public toolIsReadOnlyForUser = false;
  public toolReadOnlyMessage = '';
  dataList = true;
  selectedLookup: {
    name: string;
    symbol: string;
    exchange: string;
  } = {
    name: '',
    symbol: '',
    exchange: '',
  };

  ngOnInit() {
    this.menuService.getCloseMenuSubject().subscribe((filterObject) => {
      this.setFilterParams(filterObject);
    });
    this.menuService.getTriggerResetFilter().subscribe((filterObject) => {
      if (filterObject) {
        const { data = [], meta, filterCount } = filterObject.filterObject;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.filterCountShow = filterCount > 0 ? filterCount : 0;
      }
    });
    if (this.dataList) {
      this.applyFilter({ offset: 0 });
    }

    this.activatedRoute.url.subscribe((urlSegment) => {
      const isProductTrue = this.router.url.includes('product/true');
      this.hideCryptoDetails = isProductTrue;
    });
  }

  setFilterParams(filterObject) {
    this.selectedPrice = filterObject['price'];
    this.selectedVolume = filterObject['volume'];
    this.selectedEarnings = filterObject['earnings'];
    this.selectedMarketCap = filterObject['marketCap'];
    this.selectedChange = filterObject['changes'];
  }

  async createEditWatchList(mode) {
    this.mode = mode;
    let watchListProps: any = {
      mode,
      componentType: 'SetupScannerAddEditComponent',
    };
    if (mode == 'edit') {
      watchListProps.editData = this.watchListRow;
    }
    this.menuService.openRightMenu(watchListProps);
  }

  viewSymbol(symbol: string) {
    this.toolsApi.setContext('setup-scanner');
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
  }

  toggleDropdown(event: Event, row: any) {
    event.preventDefault();
    event.stopPropagation();
    // Close dropdown if clicking on a different row
    if (this.selectedRow === row) {
      this.isDropdownOpen = !this.isDropdownOpen;
    } else {
      this.isDropdownOpen = true;
      this.selectedRow = row;
    }
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }

  back() {
    window.location.reload();
  }

  getHeaderClassChangePercent({ row }): any {
    return {
      'custom-cell-green': Number(row.regularMarketChangePercent) >= 0,
      'custom-cell-red': Number(row.regularMarketChangePercent) < 0,
    };
  }

  applyFilter(page) {
    this.isLoading = true;
    let filterObject = { page: page.offset + 1, pageSize: page.pageSize };
    if (this.selectedPrice && this.selectedPrice !== 'any') {
      filterObject['price'] = this.selectedPrice;
    }

    if (this.selectedVolume && this.selectedVolume !== 'any') {
      filterObject['volume'] = this.selectedVolume;
    }

    if (this.selectedEarnings && this.selectedEarnings !== 'any') {
      filterObject['earnings'] = this.selectedEarnings;
    }

    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') {
      filterObject['marketCap'] = this.selectedMarketCap;
    }

    if (this.selectedChange && this.selectedChange !== 'any') {
      filterObject['changes'] = this.selectedChange;
    }

    if (this.selectedCountry && this.selectedCountry !== 'any') {
      filterObject['country'] = this.selectedCountry;
    }

    if (this.selectedIndustry && this.selectedIndustry !== 'any') {
      filterObject['industry'] = this.selectedIndustry;
    }

    if (this.selectedSector && this.selectedSector !== 'any') {
      filterObject['sector'] = this.selectedSector;
    }

    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') {
      filterObject['50DAvgChg'] = this.selected50DAvgChg;
    }

    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') {
      filterObject['200DAvgChg'] = this.selected200DAvgChg;
    }
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') {
      filterObject['52WHighChg'] = this.selected52WHighChg;
    }
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.dataList = false;
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.isLoading = false;
      });
  }

  sortDataPrice() {
    const sortedData = [...this.rows].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketPrice - b.regularMarketPrice;
      } else {
        return b.regularMarketPrice - a.regularMarketPrice;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }
  sortDataChange() {
    const sortedData = [...this.rows].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketChangePercent - b.regularMarketChangePercent;
      } else {
        return b.regularMarketChangePercent - a.regularMarketChangePercent;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }

  sortDataMarketCap() {
    console.log(this.rows);
    const sortedData = [...this.rows].sort((a, b) => {
      const marketCapA = this.parseMarketCap(a.marketCap);
      const marketCapB = this.parseMarketCap(b.marketCap);

      if (this.sortDirection === 'asc') {
        return marketCapA - marketCapB;
      } else {
        return marketCapB - marketCapA;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }

  parseMarketCap(marketCap: string): number {
    const value = parseFloat(marketCap);
    if (marketCap.includes('T')) {
      return value * 1e12; // Trillions
    } else if (marketCap.includes('B')) {
      return value * 1e9; // Billions
    } else if (marketCap.includes('M')) {
      return value * 1e6; // Millions
    } else if (marketCap.includes('K')) {
      return value * 1e3; // Thousands
    } else {
      return value; // Raw number
    }
  }
  sortDataVolume() {
    console.log(this.rows);
    const sortedData = [...this.rows].sort((a, b) => {
      const volumeA = parseInt(a.regularMarketVolume.replace(/,/g, ''), 10);
      const volumeB = parseInt(b.regularMarketVolume.replace(/,/g, ''), 10);

      if (this.sortDirection === 'asc') {
        return volumeA - volumeB;
      } else {
        return volumeB - volumeA;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }

  async addToWatchlist(symbol: string) {
    const modal = await this.modalController.create({
      component: WatchListCreateAddModalComponent,
      componentProps: {
        selectedSymbol: symbol,
      },
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.toolsApi.addWatchList(data).subscribe((response: any) => {
          if (response && response.message) {
            this.toastServ.presentToast(response.message, 'success');
          }
        });
      }
    });
    return await modal.present();
  }

  openRightMenu() {
    this.destroyInterval();
    const filterData = {
      componentType: 'FilterSetupScannerListComponent',
    };
    this.menuService.openRightMenu(filterData);
  }

  destroyInterval() {
    if (this.interval) {
      this.timeOutPageTime = 0;
      clearInterval(this.interval);
      this.interval = 0;
    }
  }
}
