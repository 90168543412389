<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ion-row class="tracker-details-row">
      <ion-col size="4" class="tracker-details-col nav-data">
        <div>
          <ol class="cd-breadcrumb custom-separator custom-icons">
            <li><a routerLink="/{{ role }}/dashboard">Tools</a></li>
            <li>
              <a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools Dashboard</a>
            </li>
            <li class="current" *ngIf="selectedLookup && selectedLookup?.symbol">
              {{ selectedLookup?.symbol | uppercase }}
            </li>
          </ol>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <h1 class="heading-title">Welcome {{ username }}, Good to See you!</h1>
    </ion-row>
    <ion-row class="tracker-details-row search-section">
      <ion-col size="6" class="tracker-details-col">
        <ion-searchbar
          class="searchbar-green"
          search-icon="search-outline"
          debounce="1000"
          id="search"
          placeholder="Type a ticker symbol here..."
          (ionChange)="onSearchLookup($event)"
          (keydown)="handleKeyDown($event)"
          [value]="
            selectedLookup.symbol && selectedLookup.name
              ? selectedLookup.symbol + ' - ' + selectedLookup.name
              : ''
          "
        >
        </ion-searchbar>
        <div
          class="taglist-search"
          *ngIf="
            lookupListVisibility &&
            !selectedLookup?.symbol &&
            searchResult &&
            searchResult.length > 0
          "
        >
          <span class="close" (click)="hideLookupList()">&#10005;</span>
          <p
            *ngFor="let item of searchResult; let i = index"
            (click)="addLookup(item.symbol)"
            [class.selected]="i === highlightedIndex"
            tabindex="0"
          >
            {{ item.symbol }} - {{ item.name }}
          </p>
        </div>
      </ion-col>
    </ion-row>
    <div>
      <ion-row class="tracker-details-row main-option">
        <ion-col class="tracker-details-col">
          <div class="card-container tracker-details">
            <div class="custom-card custom-card-block border-bottom">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <img class="product-image" src="/assets/icon/trade-trecker.png" />
                    <div class="product-description">
                      <div class="product-name">Trade Tracker</div>
                      <span class="product-desc">
                        Keep track of your trades with our Trade Tracker, offering real-time
                        insights.
                      </span>
                    </div>
                  </div>
                  <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button fill="clear" color="primary" (click)="onTradeTrackerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container tracker-details">
            <div class="custom-card custom-card-block border-bottom">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <img class="product-image" src="/assets/icon/watchlist.png" />
                    <div class="product-description">
                      <div class="product-name">Watchlist</div>
                      <span class="product-desc">
                        Stay ahead of the market with Watchlist feature, tailored to monitor
                        favorite stocks.
                      </span>
                    </div>
                  </div>
                  <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button fill="clear" color="primary" (click)="onWatchListView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container tracker-details">
            <div class="custom-card custom-card-block border-bottom">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <img class="product-image" src="/assets/icon/scanner.png" />
                    <div class="product-description">
                      <div class="product-name">Scanner</div>
                      <span class="product-desc">
                        Discover the power of our comprehensive Stock Scanner, designed to sift.
                      </span>
                    </div>
                  </div>
                  <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button fill="clear" color="primary" (click)="onScannerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="tracker-details-row main-section">
        <ion-col class="tracker-details-col">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <div class="climate-name">Treasure Hunting Climate</div>
                    <!-- <div class="product-description">
                      <img class="climate-image" src="/assets/icon/climate-img.png" />
                      <span class="product-desc">
                        Sed ut perspiciatis unde omnis iste natus error sit volupm accusantium
                        doloremue ludantium.
                      </span>
                    </div> -->
                  </div>
                  <!-- <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button fill="clear" color="primary" (click)="onTradeTrackerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div> -->
                  <div class="Under-construction">
                    <div class="inactive-custom">
                      <ion-icon name="construct-outline"></ion-icon>
                      <h1>Under construction</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <div class="climate-name">Recent Alerts</div>
                    <div class="Under-construction">
                      <div class="inactive-custom">
                        <ion-icon name="construct-outline"></ion-icon>
                        <h1>Under construction</h1>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <div class="ion-no-padding">
                      <div class="Under-construction">
                        <div class="inactive-custom">
                          <ion-icon name="construct-outline"></ion-icon>
                          <h1>Under construction</h1>
                        </div>
                      </div>
                      <ngx-datatable
                        #externalSpendTable
                        class="material body-cell-stacked"
                        [columnMode]="'force'"
                        [rowHeight]="'auto'"
                        [headerHeight]="50"
                        [footerHeight]="40"
                        [externalPaging]="true"
                        [scrollbarH]="true"
                      >
                        <ngx-datatable-column [sortable]="false">
                          <ng-template ngx-datatable-header-template>Recent Alerts</ng-template>
                          <ng-template
                            let-row="row"
                            let-title="row.title"
                            let-link="row.link"
                            ngx-datatable-cell-template
                          >
                            <span (click)="link && openLink(link)"> Stock Price Alert </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false">
                          <ng-template
                            let-row="row"
                            let-source="row.source"
                            let-pubDate="row.pubDate"
                            ngx-datatable-cell-template
                          >
                            <span class="recent-news-name">Price crosses $40. </span>
                            <span class="pub-date"> • {{ pubDate | date : 'h:mm a' }}</span>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container treasure-details treasure-details-upcoming no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <div class="climate-name">Upcoming Earnings</div>
                    <div>
                      <div class="loading-spinner" *ngIf="isLoading"></div>
                      <div class="ion-no-padding">
                        <ngx-datatable
                          *ngIf="!isLoading"
                          class="external-analytics material"
                          [rows]="EarningsStockDetails"
                          [rowHeight]="'45'"
                          [headerHeight]="50"
                          [footerHeight]="0"
                          [limit]="10"
                          [scrollbarH]="'true'"
                        >
                          <ngx-datatable-column
                            [width]="200"
                            [sortable]="false"
                            [resizeable]="false"
                          >
                            <ng-template ngx-datatable-header-template>TICKER</ng-template>
                            <ng-template
                              ngx-datatable-cell-template
                              let-symbol="row.symbol"
                              let-stockUrl="row.stockUrl"
                            >
                              <div class="symbol-img-name">
                                <img class="size-css" src="{{ stockUrl }}" aria-placeholder="stockimage"/>
                                <span>{{ symbol }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column
                            [width]="30"
                            [sortable]="false"
                            [resizeable]="false"
                          >
                            <ng-template ngx-datatable-header-template>PRICE </ng-template>
                            <ng-template
                              ngx-datatable-cell-template
                              let-regularMarketPrice="row.regularMarketPrice"
                            >
                              {{ regularMarketPrice }}
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column
                            [cellClass]="getHeaderClassChangePercent"
                            [width]="70"
                            [resizeable]="false"
                            [sortable]="false"
                          >
                            <ng-template ngx-datatable-header-template>CHANGE</ng-template>
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <span [ngClass]="getHeaderClassTodayChange({ row: row })">
                                {{ row.regularMarketChange | number : '1.2-2' }}</span
                              >
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column
                            [width]="70"
                            [sortable]="false"
                            [resizeable]="false"
                          >
                            <ng-template ngx-datatable-header-template>VOLUME</ng-template>
                            <ng-template
                              ngx-datatable-cell-template
                              let-regularMarketVolume="row.regularMarketVolume"
                            >
                              {{ regularMarketVolume }}
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
                      </div>
                      <div class="product-positions-container">
                        <div class="product-view-positions">
                          <ion-button
                          size="small"
                            fill="clear"
                            color="primary"
                            (click)="onUpcomingEarningsView()"
                            >View ALL STOCKS</ion-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="tracker-details-row">
        <ion-col class="tracker-details-col insights-details">
          <div class="card-container treasure-details">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <div class="climate-name">Team Insights</div>
                    <div class="Under-construction">
                      <div class="inactive-custom">
                        <ion-icon name="construct-outline"></ion-icon>
                        <h1>Under construction</h1>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <div class="ion-no-padding">
                      <h3>Team Insights</h3>
                           [rowClass]="isRowClass"
                      <ngx-datatable
                        class="external-analytics material"
                        [columnMode]="'force'"
                        [rowHeight]="'45'"
                        [headerHeight]="65"
                        [footerHeight]="40"
                        [scrollbarH]="'true'"
                      >
                        <ngx-datatable-column
                          [width]="100"
                          [width]="200"
                          [sortable]="false"
                          [isTreeColumn]="true"
                          [resizeable]="false"
                        >
                          <ng-template ngx-datatable-header-template>Name</ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column [width]="80" [sortable]="false" [resizeable]="false">
                          <ng-template ngx-datatable-header-template
                            ><span class="color"> Earned(%)</span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="80" [sortable]="false" [resizeable]="false">
                          <ng-template ngx-datatable-header-template>
                            <span class="color1"> Lost (%)</span></ng-template
                          >
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </ion-col>
        <ion-col class="tracker-details-col insights-details">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <div class="climate-name">Recent News</div>
                    <div>
                      <div class="loading-spinner" *ngIf="isLoading"></div>
                      <div class="ion-no-padding">
                        <ngx-datatable
                        #externalSpendTable
                        class="material body-cell-stacked"
                        [rows]="selectedNews"
                        [columnMode]="'force'"
                        [rowHeight]="'auto'"
                        [headerHeight]="0"
                        [footerHeight]="0"
                        [limit]="4"
                        [externalPaging]="true"
                        [scrollbarH]="true"
                        [count]="page.totalElements"
                        [offset]="page.offset"
                        
                      >
                        <ngx-datatable-column [sortable]="false">
                          
                          <ng-template
                            let-row="row"
                            let-title="row.title"
                            let-link="row.link"
                            ngx-datatable-cell-template
                          >
                            <span (click)="link && openLink(link)">
                              {{ title }}
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false">
                          <ng-template
                            let-row="row"
                            let-source="row.source"
                            let-pubDate="row.pubDate"
                            ngx-datatable-cell-template
                          >
                            <span class="recent-news-name">{{ source }} </span>
                            <span class="pub-date"> • {{ pubDate | date : 'h:mm a' }}</span>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                      </div>
                      <div class="product-positions-container">
                        <div class="product-view-positions">
                          <ion-button
                          size="small"
                            fill="clear"
                            color="primary"
                            (click)="onUpcomingEarningsView()"
                            >View ALL NEWS</ion-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
