import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { ToastService } from 'src/app/services/toast.service';
import { MenuController } from '@ionic/angular';
import { ALERT_TYPES, CONDITION_TYPES } from 'src/app/utils/constant';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';
import { min } from 'lodash';
@Component({
  selector: 'app-ticker-alerts',
  templateUrl: './ticker-alerts.component.html',
  styleUrls: ['./ticker-alerts.component.scss'],
})
export class TickerAlertsComponent implements OnInit {
  @Input() data: any;
  public tickerAlertForm: FormGroup;
  customPopoverOptions: any = {
    showBackdrop: false,
  };
  public datePickerOptions = {
    classes: 'ion-item-no-inner-padding',
    lines: 'none',
    labelPosition: '',
  };
  conditionTypes = CONDITION_TYPES;
  alertTypes = ALERT_TYPES;
  selectedSegment = 'active';
  selected = 'alertList';
  showForm = false;
  activeAlerts: any = [];
  inactiveAlerts: any = [];
  alertType = '';
  isChecked1: boolean = false;
  isChecked2: boolean = false;
  editSlug: string;
  showDatePicker: boolean = false;
  selectedDateTime: string;
  currentDate: string;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private toolService: ToolServiceService,
    private toastServ: ToastService,
    private menuService: commonRightMenuService,
    public menuController: MenuController,
    private cdr: ChangeDetectorRef
  ) {
    const minDate = moment.utc(new Date()).format('YYYY-MM-DD');
    this.currentDate = minDate;
    this.tickerAlertForm = this.formBuilder.group({
      alertType: ['', Validators.required],
      conditionType: [''],
      expirationDate: [''],
      triggerDate: [''],
      triggerPrice: [''],
      optionLegType: [''],
      status: [''],
      currentPrice: [''],
      message: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.showDatePicker = false;
    this.setCurrentPrice();
    // this.tickerAlertForm.patchValue({ ['alertType']: 'date-time' });
    this.getAlertList();
    this.menuService.getOpenMenuSubject().subscribe((data) => {
      this.data = data;
      this.showDatePicker = false;
    });
  }

  setCurrentPrice() {
    this.tickerAlertForm.get('currentPrice').setValue(this.data.stockPrice);
  }

  getAlertList() {
    let slug = this.data.slug;
    this.toolService.getAlertData(slug).subscribe((res) => {
      if (res.data && res.data?.alertList && res.data?.alertList.length) {
        const alertData = res.data.alertList;
        this.activeAlerts = alertData.filter((alert) => alert.status === 'active');
        this.inactiveAlerts = alertData.filter((alert) => alert.status === 'inactive');
      }
    });
  }

  cancel() {
    this.menuController.close();
  }

  onAlertDateChange(event, field) {
    this.tickerAlertForm.patchValue({ [field]: event });
    this.tickerAlertForm.get(field).updateValueAndValidity();
  }

  onAlertCreateSubmit() {
    let triggerDate = 0;
    let triggerPrice = 0;
    let optionType = 0;
    const message = this.tickerAlertForm.get('message').value;
    const alertType = this.tickerAlertForm.get('alertType').value;
    const conditionType = this.tickerAlertForm.get('conditionType').value;
    const expirationDate = this.tickerAlertForm.get('expirationDate').value;
    const status = this.tickerAlertForm.get('status').value ? 'active' : 'inactive';
    const slug = this.data.slug;
    const currentDate = new Date();
    const oneMonthAhead = new Date();
    const expirationDateValue = this.tickerAlertForm.get('expirationDate').value;
    oneMonthAhead.setMonth(currentDate.getMonth() + 1);
    const maxDate = this.dateFormate(oneMonthAhead);
    this.selectedDateTime = expirationDateValue
      ? expirationDateValue
      : this.dateFormate(currentDate);
    if (
      alertType === 'stock_price' &&
      this.selectedDateTime < maxDate &&
      this.selectedDateTime >= this.dateFormate(currentDate)
    ) {
      this.toastServ.presentToast('Please select the valid date.', 'danger');
      return;
    } else {
      if (alertType === 'on_date') {
        triggerDate = this.tickerAlertForm.get('triggerDate').value;
      }
      if (alertType === 'stock_price') {
        triggerPrice = this.tickerAlertForm.get('triggerPrice').value;
      }
      if (alertType === 'option_price') {
        triggerPrice = this.tickerAlertForm.get('triggerPrice').value;
        optionType = this.tickerAlertForm.get('optionLegType').value;
      }
      const payload = {
        message: message,
        tickerSlug: slug,
        status: status,
        alertType: alertType,
        conditionType: conditionType,
        expirationDate: expirationDate,
        ...(alertType === 'on_date' ? { triggerDate: triggerDate } : {}),
        ...(alertType === 'stock_price' ? { triggerPrice: triggerPrice } : {}),
        ...(alertType === 'option_price'
          ? { triggerPrice: triggerPrice, optionType: optionType }
          : {}),
      };
      if (!this.editSlug) {
        this.toolService.createAlert(payload).subscribe((res: any) => {
          if (res) {
            this.tickerAlertForm.reset();
            this.setCurrentPrice();
            this.showForm = false;
            this.toastServ.presentToast(res?.message, 'success');
            this.getAlertList();
          }
        });
      } else {
        this.toolService.updateAlert(payload, this.editSlug).subscribe((res: any) => {
          if (res) {
            this.tickerAlertForm.reset();
            this.setCurrentPrice();
            this.showForm = false;
            this.toastServ.presentToast(res?.message, 'success');
            this.getAlertList();
          }
        });
      }
    }
  }

  dateFormate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }

  toggleView(alertDetail?: any) {
    this.showForm = !this.showForm;
    this.alertType = '';
    const formattedExpirationDate = new Date(alertDetail?.expirationDate);
    const formattedDateString = `${
      formattedExpirationDate.getMonth() + 1
    }/${formattedExpirationDate.getDate()}/${formattedExpirationDate.getFullYear()}`;
    this.editSlug = undefined;
    if (alertDetail) {
      this.alertType = alertDetail.type;
      this.editSlug = alertDetail.slug;
      this.tickerAlertForm.patchValue({
        message: alertDetail.message,
        alertType: alertDetail.type,
        triggerDate: alertDetail.triggerDate,
        triggerPrice: alertDetail.triggerPrice,
        optionLegType: alertDetail.optionType,
        conditionType: alertDetail.conditionType,
        expirationDate: formattedDateString,
        status: alertDetail.status == 'active' ? true : false,
      });
    } else {
      this.tickerAlertForm.reset();
      this.setCurrentPrice();
    }
  }

  selectedOption() {
    this.showDatePicker = false;
    this.alertType = this.tickerAlertForm.get('alertType').value;
    this.tickerAlertForm.controls['triggerPrice'].reset();
    this.tickerAlertForm.controls['triggerPrice'].clearValidators();
    if (this.alertType === 'option_price' || this.alertType === 'stock_price') {
      this.tickerAlertForm.controls['triggerPrice'].setValidators([Validators.required]);
      this.tickerAlertForm.controls['triggerPrice'].setValidators([validPriceValidator()]);
    }
    if (this.alertType === 'stock_price') {
      this.tickerAlertForm.controls['conditionType'].setValidators([Validators.required]);
    }
    this.tickerAlertForm.controls['conditionType'].updateValueAndValidity();
  }

  getAllFormErrors(): any {
    const errors = {};
    Object.keys(this.tickerAlertForm.controls).forEach((controlName) => {
      const control = this.tickerAlertForm.get(controlName);
      if (control && control.errors) {
        errors[controlName] = control.errors;
      }
    });

    return errors;
  }

  closeDatePicker(event) {
    const value = event.target.value;
    this.tickerAlertForm.patchValue({
      expirationDate: this.formatDate(value),
    });
    this.showDatePicker = false;
    if (!value) return this.tickerAlertForm.get('expirationDate').setErrors(Validators.required);
    const selectedDate = new Date(value);
    const currentDate = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(currentDate.getDate() + 30);

    if (selectedDate >= thirtyDaysFromNow || selectedDate <= currentDate) {
      this.tickerAlertForm.get('expirationDate').setErrors(null);
    } else {
      this.tickerAlertForm.get('expirationDate').setErrors({ invalidDate: true });
    }
  }

  openDatePicker() {
    if (!this.showDatePicker) {
      this.showDatePicker = true;
      const expirationDateValue = this.tickerAlertForm.get('expirationDate').value;
      this.selectedDateTime = expirationDateValue ? expirationDateValue : new Date().toISOString();
      this.cdr.detectChanges();
    }
  }

  formatDate(date: string): string {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  }
}

// Custom validator function for price validation
export function validPriceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const priceRegex = /^\d+(\.\d{1,2})?$/; // Regular expression to validate price with up to two decimal places
    const validPrice = priceRegex.test(control.value);

    return validPrice ? null : { invalidPrice: true };
  };
}
