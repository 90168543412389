import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ToolDashboardRoutingModule } from './tool-dashboard-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { WatchListCreateAddModalComponent } from './watchlist-create-add-modal.component';
import { ToolDashboardSymbolDetailsComponent } from './tool-dashboard-symbol-details/tool-dashboard-symbol-details.component';

@NgModule({
  declarations: [WatchListCreateAddModalComponent, ToolDashboardSymbolDetailsComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    ToolDashboardRoutingModule,
    IonicModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToolDashboardModule {}
