import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NotesRoutingModule } from 'src/app/components/notes/notes-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { ToolNotesComponent } from './tool-notes.component';

@NgModule({
  declarations: [ToolNotesComponent],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, AppDatePickerModule],
  exports: [ToolNotesComponent],
})
export class ToolNotesModule {}
