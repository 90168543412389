<ion-content class="ion-padding buyers-information tse" appScrollbar>
  <div class="page-heading">
    <ion-row>
      <h2>Ticker</h2>
      <ion-searchbar
        search-icon="search-outline"
        debounce="1000"
        id="search"
        class="search ion-no-padding"
        (ionClear)="onClearSearch()"
        (ionChange)="onSearchCategory($event, 'search')"
        placeholder="Search by Ticker Symbol"
      >
      </ion-searchbar>
    </ion-row>
  </div>
  <div>
    <ngx-datatable
      class="user-view material"
      [rows]="stockDetails"
      [columnMode]="'force'"
      [rowHeight]="'45'"
      [headerHeight]="50"
      [footerHeight]="40"
      [scrollbarH]="true"
    >
      <ngx-datatable-column [width]="40" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>TICKER</ng-template>
        <ng-template ngx-datatable-cell-template let-row="row" class="xyz">
          <span class="row-data">{{ row.symbol }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="120" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>COMPANY NAME</ng-template>
        <ng-template ngx-datatable-cell-template let-row="row" class="xyz">
          <span class="row-company-data">{{ row.company }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="50" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataPrice()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>LAST</span>
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.regularMarketPrice }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="60" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataChange()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>CHANGE</span>
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <span [ngClass]="getHeaderClassTodayChange({ row: row })">
            {{ row.regularMarketChange | number : '1.2-2' }}</span
          >
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="60" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataChangePer()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>CHANGE%</span>
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <span [ngClass]="getHeaderClassTodayChangePer({ row: row })">
            {{ row.regularMarketChangePercent | number : '1.2-2' }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template> ACTION </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="crypto-details-button">
            <ion-button
              class="color-css1"
              fill="outline"
              *ngIf="!row.isInWatchlist && !selectedSymbol.includes(row.symbol)"
              (click)="toggleWatchlist(row)"
            >
              <ion-icon name="add-outline" fill="outline"></ion-icon>
              Add to Watchlist
            </ion-button>
            <ion-button
              class="color-css1 remove-btn"
              fill="outline"
              *ngIf="row.isInWatchlist || selectedSymbol.includes(row.symbol)"
              (click)="toggleWatchlist(row)"
            >
              Remove
            </ion-button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <div class="sticky-button">
      <ion-button color="dark" fill="outline" (click)="close()"> Close </ion-button>
    </div>
  </div>
</ion-content>
