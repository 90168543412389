import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SetupScannerRoutingModule } from './setup-scanner-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { SetupScannerDataListingComponent } from './setup-scanner-data-listing/setup-scanner-data-listing.component';
import { FilterSetupScannerListComponent } from './filter-setup-scanner-list/filter-setup-scanner-list.component';

@NgModule({
  declarations: [SetupScannerDataListingComponent, FilterSetupScannerListComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    SetupScannerRoutingModule,
    IonicModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
  ],
  exports: [SetupScannerDataListingComponent, FilterSetupScannerListComponent],
  entryComponents: [SetupScannerDataListingComponent, FilterSetupScannerListComponent],
})
export class SetupScannerModule {}
