<ng-container [ngSwitch]="menuType">
  <ng-container *ngSwitchCase="'alertComponent'">
    <app-ticker-alerts [data]="parentData"></app-ticker-alerts>
  </ng-container>
  <ng-container *ngSwitchCase="'positionComponent'">
    <app-add-potion-modal [componentData]="parentData"></app-add-potion-modal>
  </ng-container>
  <ng-container *ngSwitchCase="'notesComponent'">
    <app-notes [notesData]="parentData"></app-notes>
  </ng-container>
  <ng-container *ngSwitchCase="'toolNotesComponent'">
    <app-tool-notes [toolNotesData]="parentData"></app-tool-notes>
  </ng-container>
  <ng-container *ngSwitchCase="'HelpSectionComponent'">
    <app-help-section [helpSectionData]="parentData"></app-help-section>
  </ng-container>
  <ng-container *ngSwitchCase="'WatchListAddEditComponent'">
    <app-watch-list-add-edit [watchListData]="parentData"></app-watch-list-add-edit>
  </ng-container>
  <ng-container *ngSwitchCase="'FilterSetupScannerListComponent'">
    <app-filter-setup-scanner-list
      [setupScannerFilterData]="parentData"
    ></app-filter-setup-scanner-list>
  </ng-container>
</ng-container>
