import { Injectable } from '@angular/core';
import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TradeToolsService {
  headers = new Headers();
  token: any;
  constructor(private http: HttpClient, private storagesrv: StorageService) {}

  getAllToolTypes() {
    return this.http.get(`${baseUrl}/tools/`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  createToolType(formData) {
    return this.http.post(`${baseUrl}/tools/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  public getToolDetails(toolSlug, disableHttpLoader: string = 'false') {
    return this.http.get(`${baseUrl}/tools/${toolSlug}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  editToolType(slug, formData) {
    return this.http.put(`${baseUrl}/tools/${slug}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  public getUrlMetaData(url: any) {
    const qp = new URLSearchParams();
    if (url) {
      qp.set('url', url);
    }
    return this.http.get(`${baseUrl}/channel/url/metadata?${qp.toString()}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', 'true')
        .set('cache-request', 'true'),
    });
  }

  public uploadThreadFiles(files) {
    return this.http.post(`${baseUrl}/channel/upload`, files, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  sortToolSettingOrder(data) {
    return this.http.patch(`${baseUrl}/tools/sortToolSettingOrder`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  public searchInTool(
    channelId,
    keyword,
    exactPhrase: any = false,
    disableHttpLoader: string = 'true'
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('channelId', channelId);
    queryParams = queryParams.append('keyword', keyword);
    queryParams = queryParams.append('exactPhrase', exactPhrase);

    return this.http.get(`${baseUrl}/channel/search/keyword`, {
      params: queryParams,
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  setupScannerWeeklyStock(data, disableHttpLoader = 'true') {
    return this.http.post(`${baseUrl}/tools/dashboard/get-weekly-stock`, data, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }
}
