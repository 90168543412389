<ion-content class="ion-padding" appScrollbar>
  <ol class="cd-breadcrumb custom-separator custom-icons">
    <li><a routerLink="/{{ role }}/dashboard">Tools</a></li>
    <li><a (click)="back()"> Trade Trackers</a></li>
    <li class="current">Money Press Method</li>
  </ol>
  <div class="page-heading">
    <span class="back-trade" (click)="back()"> < Back To Trade Tracker</span>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-header>
      <div class="ion-flex-container ion-align-items-center flex-wraps">
        <h2 style="color: black; width: 40%">Money Press Method</h2>
        <div class="filter-dropdown">
          <span>Filter By:</span>
          <ion-select
            (ionChange)="statusChange($event)"
            lines="none"
            placeholder="Status"
            interface="popover"
          >
            <ion-select-option value="open">Open</ion-select-option>
            <ion-select-option value="close">Close</ion-select-option>
          </ion-select>
        </div>
        <ion-searchbar
          search-icon="search-outline"
          debounce="1000"
          id="search"
          class="search ion-no-padding"
          (ionClear)="onClearSearch($event)"
          (ionChange)="onSearch($event)"
          placeholder="Search by Ticker name"
        >
        </ion-searchbar>
        <div class="actions">
          <ion-buttons>
            <ion-button class="border-style" (click)="addTrade()" color="primary" fill="solid">
              <ion-icon slot="start" name="add-outline"></ion-icon>
              Add Trade
            </ion-button>
          </ion-buttons>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content class="ion-no-padding table-data">
      <ngx-datatable
        class="material header-space"
        [rows]="rows"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [headerHeight]="60"
        [footerHeight]="50"
        [scrollbarH]="true"
        (activate)="getSelectedRow($event)"
      >
        <!-- <ngx-datatable-column [minWidth]="100" prop="email"> -->
        <!-- <ng-template ngx-datatable-header-template> TICKER </ng-template> -->
        <!-- <ng-template let-row="row" let-symbol="row.symbol" ngx-datatable-cell-template> -->
        <!-- {{ symbol | uppercase }} -->
        <!-- </ng-template> -->
        <!-- </ngx-datatable-column> -->
        <ngx-datatable-column [minWidth]="100" prop="email">
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortData('symbol')">
              <span>TICKER</span>
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div>
            </div>

            <!-- Use an appropriate icon -->
          </ng-template>
          <ng-template let-row="row" let-symbol="row.symbol" ngx-datatable-cell-template>
            {{ symbol | uppercase }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [cellClass]="getStockPriceClass" [minWidth]="100" prop="email">
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="toggleSort()">
              <span>STOCK PRICE </span>
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div></div
          ></ng-template>
          <ng-template
            let-row="row"
            let-stockPrice="row.stockPrice"
            let-stockPricePercent="row.stockPricePercent"
            ngx-datatable-cell-template
          >
            <ng-container *ngIf="row.stockPriceDayWise > 0">
              <ion-icon name="arrow-up" color="primary" style="font-size: 20px"></ion-icon>
            </ng-container>
            <ng-container *ngIf="row.stockPriceDayWise < 0">
              <ion-icon name="arrow-down" color="danger" style="font-size: 20px"></ion-icon>
            </ng-container>
            {{ stockPrice || 0 | currency }}({{ stockPricePercent?.toFixed(2) }}%)
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="getClassForEarningDays"
          [minWidth]="100"
          prop="email"
          prop="daysDifference"
        >
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortDataEarning()">
              <span>EARNINGS</span>
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div></div
          ></ng-template>
          <ng-template
            let-row="row"
            let-nextEarningDate="row.nextEarningDate"
            ngx-datatable-cell-template
          >
            {{ row.daysDifference || 0 }} Days
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [cellClass]="getHeaderClassDayPnL" [minWidth]="80" [sortable]="true" prop="email"> -->
        <!-- <ng-template ngx-datatable-header-template> DAY PnL </ng-template> -->
        <!-- <ng-template let-row="row" let-stockPriceDayWise="row.stockPriceDayWise" ngx-datatable-cell-template> -->
        <!-- <div *ngIf="stockPriceDayWise < 0">  -->
        <!-- <span>{{ stockPriceDayWise | currency }}</span> -->
        <!-- </div> -->
        <!-- <div *ngIf="stockPriceDayWise >= 0">  -->
        <!-- <span>+{{ stockPriceDayWise | currency }}</span> -->
        <!-- </div> -->
        <!-- </ng-template> -->
        <!-- </ngx-datatable-column> -->
        <ngx-datatable-column
          [cellClass]="getHeaderClassCumulativeProfitLoss"
          [minWidth]="80"
          prop="email"
          prop="cumulativeProfitLoss"
        >
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortPnl()">
              <span>POS. PnL </span>
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div></div
          ></ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            <div *ngIf="cumulativeProfitLoss < 0">
              <span>{{ cumulativeProfitLoss | currency }}</span>
            </div>
            <div *ngIf="cumulativeProfitLoss > 0">
              <span>+{{ cumulativeProfitLoss | currency }}</span>
            </div>
            <div *ngIf="cumulativeProfitLoss == 0">
              <span>+{{ cumulativeProfitLoss | currency }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [minWidth]="100">
          <ng-template ngx-datatable-header-template>STATUS </ng-template>
          <ng-template let-row="row" let-status="row.status" ngx-datatable-cell-template>
            <ion-chip [outline]="true" [color]="status == 'close' ? 'danger' : 'primary'">
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': status == 'close',
                  'dot-position-open': status == 'open'
                }"
              ></span>
              <ion-text>
                {{ status | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [minWidth]="100" prop="email"> -->
        <!-- <ng-template ngx-datatable-header-template> -->
        <!-- <div class="sorting-data"> -->
        <!-- <span>ALERTS </span> -->
        <!-- <div class="sort-arrows"> -->
        <!-- <ion-icon name="caret-up-outline"></ion-icon> -->
        <!-- <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- </ng-template> -->
        <!-- <ng-template let-row="row" let-symbol="row.totalAlerts" ngx-datatable-cell-template> -->
        <!-- <ion-chip [outline]="true" color="medium"> -->
        <!-- <ion-icon name="notifications-outline"></ion-icon> -->
        <!-- <ion-text> -->
        <!-- {{ row.totalAlerts }} -->
        <!-- </ion-text> -->
        <!-- </ion-chip> -->
        <!-- </ng-template> -->
        <!-- </ngx-datatable-column> -->
        <ngx-datatable-column [minWidth]="50" prop="email">
          <ng-template ngx-datatable-header-template>NOTES </ng-template>
          <ng-template let-row="row" let-symbol="row.totalNotes" ngx-datatable-cell-template>
            <ion-chip [outline]="true" color="medium">
              <ion-icon name="document-text-outline"></ion-icon>
              <ion-text>
                {{ row.totalNotes }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ion-card-content>
  </ion-card>
</ion-content>
