import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  ViewChild,
  ɵɵtrustConstantResourceUrl,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import moment from 'moment';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-add-potion-modal',
  templateUrl: './add-potion-modal.component.html',
  styleUrls: ['./add-potion-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPotionModalComponent implements OnInit {
  @ViewChild('dateInput') dateInput: any; // Reference to the input element
  selectedDateTime: string;
  showDatePicker: boolean;
  symbol: string;
  stockPrice: string;
  slug: string;
  formType: string;
  mode;
  positionList: any;
  isDateSelected = false;
  editData: any;
  @Input() componentData: any;
  addOption: FormGroup;
  formTitle: string;
  listOfExpiration: any[] = [];
  currentRoute: any;
  selectedExpirationDate: number;
  positionDetail = [];
  stockDetails: any;
  statusChange = 'open';
  isStatusChecked: boolean = false;
  highlightSelectedDateTime: boolean;
  formattedDate: string;
  todayDate: any;
  constructor(
    private formBuilder: FormBuilder,
    private toolsService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService,
    private cdr: ChangeDetectorRef
  ) {
    this.addOption = this.formBuilder.group({
      purchasedDate: [this.formattedDate, Validators.required],
      expirationDate: ['', Validators.required],
      strikeBought: ['', Validators.required],
      underlyingPrice: ['', Validators.required],
      contracts: ['', Validators.required],
      positionStatus: [''],
      premiumPaid: [''],
      exitPremium: [''],
      credit: [''],
    });
  }

  ngOnInit() {
    if (this.componentData && this.componentData.componentType === 'positionComponent') {
      this.setInitData();
    }
    this.menuService.getOpenMenuSubject().subscribe((res: any) => {
      this.componentData = {};
      if (res && res.componentType === 'positionComponent') {
        this.componentData = res;
        this.setInitData();
      }
    });
  }

  setInitData() {
    this.addOption.reset();
    if (this.componentData) {
      this.formType = this.componentData.formType;
      this.mode = this.componentData.mode;
      this.symbol = this.componentData.symbol;
      this.stockPrice = this.componentData.stockPrice;
      this.todayDate = this.componentData.todayDate;
      this.slug = this.componentData.slug;
      this.positionList = this.componentData.positionList;
      this.editData = this.componentData.editData;
      this.isStatusChecked =
        this.componentData?.editData?.positionStatus === 'closed' ? true : false;
      this.statusChange = this.componentData?.editData?.positionStatus;
    }
    this.formTitle = this.formType === 'long' ? 'Long Put Protection' : 'Weekly Sold Put';
    this.getListStrike(this.symbol);
    if (this.mode === 'add') {
      this.addOption.get('underlyingPrice').setValue(this.stockPrice);
      this.addOption.get('purchasedDate').setValue(this.todayDate);
    }
  }

  cancel(isNotify = 'true') {
    this.showDatePicker = false;
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }

  toggleStatus(event: any) {
    this.statusChange = event.target.checked ? 'closed' : 'open';
    this.isStatusChecked = event.target.checked;
  }

  getListStrike(symbol: string, event?, type?: string) {
    if (!this.isDateSelected) {
      let selectedExpirationDate = null;
      if (type && type == 'fromExpirationDate' && !event?.target?.value) {
        return;
      }
      if (event && event.target && event.target.value) {
        selectedExpirationDate = moment.utc(event.target.value, 'MM-DD-YYYY').unix();
        this.isDateSelected = true;
      }
      if (this.mode === 'edit' && !event?.target?.value) {
        const formattedDate = moment.utc(this.editData.expirationPeriod).format('MM-DD-YYYY');
        selectedExpirationDate = moment.utc(formattedDate).unix();
      }
      this.showDatePicker = false;
      this.toolsService.getStrike(symbol, selectedExpirationDate).subscribe((res: any) => {
        const body = res.data.result[0];
        if (body) {
          if (selectedExpirationDate) {
            const { options } = body;
            if (options && options.length && options[0].puts && options[0].puts.length) {
              this.isDateSelected = false;

              this.positionDetail = options[0].puts;
              this.stockDetails = body.quote;
            }
            if (this.mode === 'edit' && !event?.target?.value) {
              this.isDateSelected = false;

              this.listOfExpiration = body?.expirationDates;
              this.listOfExpiration = this.listOfExpiration.map((expiration) =>
                moment.utc(new Date(expiration * 1000)).format('MM-DD-YYYY')
              );
            }
          } else {
            this.listOfExpiration = body?.expirationDates;
            if (this.listOfExpiration.length) {
              this.isDateSelected = false;
              this.listOfExpiration = this.listOfExpiration.map((expiration) =>
                moment.utc(new Date(expiration * 1000)).format('MM-DD-YYYY')
              );
            }

            this.stockDetails = body.quote;
          }
        }
      });
      if (this.mode === 'edit') {
        if (this.editData) {
          const formattedPurchasedDate = moment
            .utc(this.editData.datePurchased)
            .format('MM/DD/YYYY');
          const formattedExpirationDate = moment
            .utc(this.editData.expirationPeriod)
            .format('MM-DD-YYYY');
          const formattedExpirationDateBefore = moment
            .utc(this.editData.expirationPeriod)
            .format('MM-DD-yyyy');
          {
            if (this.componentData.isPositionExpired === true) {
              this.addOption.get('purchasedDate').disable();
              this.addOption.get('expirationDate').disable();
              this.addOption.get('strikeBought').disable();
              this.addOption.get('underlyingPrice').disable();
              this.addOption.get('contracts').disable();
              this.addOption.get('premiumPaid').disable();
              this.addOption.get('credit').disable();
              this.addOption.patchValue({
                expirationDate: formattedExpirationDateBefore,
              });
            } else {
              this.addOption.get('purchasedDate').enable();
              this.addOption.get('expirationDate').enable();
              this.addOption.get('strikeBought').enable();
              this.addOption.get('underlyingPrice').enable();
              this.addOption.get('contracts').enable();
              this.addOption.get('premiumPaid').enable();
              this.addOption.get('credit').enable();
              this.addOption.patchValue({
                expirationDate: formattedExpirationDate,
              });
            }
            this.addOption.patchValue({
              purchasedDate: formattedPurchasedDate,
              strikeBought:
                this.formType === 'long'
                  ? this.editData.strikeBought.toString()
                  : this.editData.strikeSold.toString(),
              underlyingPrice: this.editData.underlyingStock,
              contracts: this.editData.numberOfContracts,
              premiumPaid: this.editData.premiumPaid,
              exitPremium: this.editData.exitPremium,
              credit: this.editData.premiumSold,
              positionStatus: this.editData.positionStatus,
            });
          }
        }
      }
    }
  }

  getPremiumPaid(event) {
    const selectedStrike = parseFloat(event?.detail.value);
    const selectedPosition = this.positionDetail.find(
      (position) => position.strike.raw === selectedStrike
    );
    if (selectedPosition) {
      const lastPrice = selectedPosition.lastPrice?.raw;

      if (this.formType === 'long') {
        const bid = selectedPosition.bid?.raw;
        this.addOption.get('premiumPaid').setValue(lastPrice);
        this.addOption.get('exitPremium').setValue(bid);
      }
      if (this.formType === 'weekly') {
        const ask = selectedPosition.ask?.raw;
        this.addOption.get('credit').setValue(lastPrice);
        this.addOption.get('exitPremium').setValue(ask);
      }
    }
  }

  // create function ExitPremium
  getExitPremium(strikePrice?) {
    let exitPremium = 0;
    const selectedPosition = this.positionDetail.find(
      (position) => position.strike?.raw === strikePrice
    );
    if (this.formType === 'long') {
      exitPremium = selectedPosition?.bid?.raw;
    }
    if (this.formType === 'weekly') {
      exitPremium = selectedPosition?.ask?.raw;
    }
    return exitPremium;
  }

  createLegs() {
    let putObject: any;
    let tradeProfitLoss = 0;
    let profitLossPercentage = 0;
    // let priviousCumulativeProfitLoss = 0;
    const lastPosition =
      this.positionList && this.positionList.length > 0
        ? this.positionList[this.positionList.length - 1]
        : null;
    const purchasedDate = this.addOption.get('purchasedDate').value;
    const formattedPurchasedDate = moment(purchasedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const expirationDate = this.addOption.get('expirationDate').value;
    const formattedExpireDate = moment(expirationDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const strikeBought = parseFloat(this.addOption.get('strikeBought').value);
    const underlyingPrice = parseFloat(this.addOption.get('underlyingPrice').value);
    const contracts = this.addOption.get('contracts').value;
    const premiumPaid = Number(this.addOption.get('premiumPaid').value);
    const positionStatus = this.isStatusChecked ? 'closed' : 'open';
    const exitPremium =
      positionStatus === 'closed'
        ? Number(this.addOption.get('exitPremium').value)
        : this.getExitPremium(strikeBought);
    const credit = this.addOption.get('credit').value;
    if (this.formType === 'long') {
      putObject = {
        datePurchased: formattedPurchasedDate,
        premiumPaid: premiumPaid,
        syntheticStrike: 0,
        averagePremiumLoss: 0,
        strikeBought: strikeBought,
      };
    }
    if (this.formType === 'weekly') {
      putObject = {
        dateSold: formattedPurchasedDate,
        strikeSold: strikeBought,
        premiumSold: credit,
        premiumAsPercentageOfStock: 0,
        avgPremiumEarned: 0,
      };
    }
    const payload = {
      tickerSymbol: this.symbol,
      tickerSlug: this.slug,
      positionType: this.formType,
      positionStatus: positionStatus,
      optionDirection: '',
      expirationPeriod: formattedExpireDate,
      numberOfContracts: contracts,
      tradeProfitLoss: tradeProfitLoss,
      profitLossPercentage: profitLossPercentage,
      exitPremium: exitPremium,
      underlyingStock: underlyingPrice,
      ...(this.formType === 'long' ? { longPut: putObject } : {}),
      ...(this.formType === 'weekly' ? { weeklyPut: putObject } : {}),
    };
    if (this.mode === 'add') {
      this.toolsService.createLeg(payload).subscribe((res: any) => {
        if (res) {
          this.menuService.setCloseMenuSubject(payload);
          this.addOption.reset();
          this.isStatusChecked = false;
          this.menuController.close('payload');
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
        }
      });
    }
    if (this.mode === 'edit') {
      this.toolsService.updatePosition(this.editData.slug, payload).subscribe((res: any) => {
        if (res) {
          this.menuService.setCloseMenuSubject(payload);
          this.addOption.reset();
          this.isStatusChecked = false;
          this.menuController.close('payload');
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
        }
      });
    }
  }

  closeDatePicker(event) {
    const value = event.target.value;
    this.addOption.patchValue({
      purchasedDate: this.formatDate(new Date(value)),
    });
    this.showDatePicker = false;
  }

  openDatePicker() {
    if (!this.showDatePicker) {
      this.showDatePicker = true;
      const purchasedDateValue = this.addOption.get('purchasedDate').value;
      this.selectedDateTime = purchasedDateValue ? purchasedDateValue : new Date().toISOString();
      this.cdr.detectChanges();
    }
  }

  formatDate(date: Date): string {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero for month
    const day = ('0' + date.getDate()).slice(-2); // Add leading zero for day
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
}
