<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ion-row>
      <ion-col>
        <div>
          <ol class="cd-breadcrumb custom-separator custom-icons watchlist-breadcrumb">
            <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
            <li>
              <a routerLink="/{{ role }}/tools/watchList/{{ activeToolSlug }}" (click)="back()"
                >WatchList</a
              >
            </li>
            <li class="current" *ngIf="watchListRow && watchListRow?.name">
              {{ watchListRow?.name }}
            </li>
          </ol>
        </div>
      </ion-col>
    </ion-row>

    <!-- <div *ngIf="!showDatatable"> -->
    <!-- <div class="page-heading"> -->
    <!-- <h2>Premium Watchlists</h2> -->
    <!-- </div> -->
    <!--  -->
    <!-- <swiper-container slides-per-view="1" speed="500" pagination="true"> -->
    <!-- <ng-container *ngFor="let chunk of chunkedData"> -->
    <!-- <swiper-slide> -->
    <!-- <ion-grid class="icon-grid-block"> -->
    <!-- <ion-row> -->
    <!-- <ion-col size="4" *ngFor="let row of chunk"> -->
    <!-- <ion-card> -->
    <!-- <ion-card-header> -->
    <!-- <div class="custom-img"> -->
    <!-- <img class="product-image" [src]="row.image" /> -->
    <!-- </div> -->
    <!-- <div class="custom-css"> -->
    <!-- <span class="primium-watchlist font-css">{{ row.name }}</span> -->
    <!-- <p class="font-css">{{ row.description }}</p> -->
    <!-- <span class="primium-watchList-locked font-css"> Click To Unlock </span> -->
    <!-- </div> -->
    <!-- <ion-icon class="lock-css" name="lock-closed"></ion-icon> -->
    <!-- </ion-card-header> -->
    <!-- </ion-card> -->
    <!-- </ion-col> -->
    <!-- </ion-row> -->
    <!-- </ion-grid> -->
    <!-- </swiper-slide> -->
    <!-- </ng-container> -->
    <!-- <ng-container> -->
    <!-- <swiper-slide> -->
    <!-- <ion-grid class="icon-grid-block"> -->
    <!-- <ion-row> -->
    <!-- <ion-col> -->
    <!-- <ion-card> -->
    <!-- <ion-card-header class="Under-construction-slider"> -->
    <!-- <div class="Under-construction"> -->
    <!-- <div> -->
    <!-- <ion-icon name="construct-outline"></ion-icon> -->
    <!-- <h1>Under construction</h1> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </ion-card-header> -->
    <!-- </ion-card> -->
    <!-- </ion-col> -->
    <!-- </ion-row> -->
    <!-- </ion-grid> -->
    <!-- </swiper-slide> -->
    <!-- </ng-container> -->
    <!-- </swiper-container> -->
    <!-- </div> -->

    <div class="space" *ngIf="!showDatatable">
      <div class="page-heading">
        <h2>My Watchlists</h2>
        <div class="actions">
          <div class="crypto-details-button">
            <ion-button class="color-css1" fill="outline" (click)="createEditWatchList('add')">
              <ion-icon name="add-outline" fill="outline"></ion-icon> Create WatchList
            </ion-button>
          </div>
        </div>
      </div>

      <ng-container *ngFor="let watchlistName of rows">
        <ion-slide>
          <ion-grid class="icon-grid-block">
            <ion-row>
              <ion-col size="4" *ngFor="let row of watchlistName">
                <ion-card>
                  <ion-card-header>
                    <span class="watchList">{{ row.name }}</span>
                    <span
                      class="viewWatchList"
                      routerLink="/{{ role }}/tools/watchList/{{ activeToolSlug }}/product/{{
                        row._id
                      }}"
                    >
                      View
                    </span>
                  </ion-card-header></ion-card
                ></ion-col
              ></ion-row
            ></ion-grid
          ></ion-slide
        >
      </ng-container>
    </div>
  </div>
</ion-content>
