import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { WatchListCreateAddModalComponent } from '../watchlist-create-add-modal.component';
import { Page } from 'src/app/models/page';
import { Location } from '@angular/common';
@Component({
  selector: 'app-tool-dashboard-symbol-details',
  templateUrl: './tool-dashboard-symbol-details.component.html',
  styleUrls: ['./tool-dashboard-symbol-details.component.scss'],
})
export class ToolDashboardSymbolDetailsComponent implements OnInit {
  @ViewChild('hotlistsymbol', { static: false }) hotlistsymbol?: ElementRef;
  @Input() positionProps: any;
  @Input() toolNotesData: any;
  role: any;
  rows = [];
  showForm = false;
  public tag = [];
  public page = new Page();
  listOfLookup: any[] = [];
  public searchText: string = null;
  lookupListVisibility: boolean = false;
  showDetails: boolean = false;
  searchResult: any[];
  selectedStockDetails: any;
  public selectedNews: [] = [];
  public selectedNotes: [] = [];
  public segment: string = 'stockInfo';
  public arr = new Array(25);
  public isToolAccess = false;
  isLoading = true;
  public lockedPageContent: null;
  slug: string;
  [x: string]: any;
  highlightedIndex: number = -1;
  public interval: any;
  timeOutPageTime = 0;
  mode: string;
  symbol: any;
  userId: any;
  watchList: any;
  context: 'dashboard' | 'watchlist' | 'setup-scanner' = 'dashboard';
  fromWatchlist: boolean = false;
  watchlistCount: number;
  selectedLookup: {
    name: string;
    symbol: string;
    exchange: string;
  } = {
    name: '',
    symbol: '',
    exchange: '',
  };
  buttonStateGroup = {
    is_watch_list: false,
    title: 'Add To Watchlist',
    icon: 'add-outline',
    isButtonActive: false,
    color: 'dark',
  };
  constructor(
    public storageServ: StorageService,
    private toolsApi: ToolServiceService,
    private _renderer2: Renderer2,
    private cdr: ChangeDetectorRef,
    private toastServ: ToastService,
    public modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    public loaderService: LoaderService,
    private location: Location
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    this.symbol = this.activatedRoute?.snapshot.params.symbol;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }

  ngOnInit() {
    this.menuService.getTriggerChildResetAdd().subscribe((data) => {
      console.log('data', this.selectedStockDetails.symbol);
      this.getSymbolWiseNotes(this.selectedStockDetails.symbol);
    });
    this.onStockDetails(this.symbol);
    this.onGetLookup();
  }

  toggleButtonColor() {
    this.addToWatchlist();
  }

  updateWatchListCount(count: number) {
    this.watchListCount = count;
  }

  goBack() {
    this.location.back();
  }

  handleKeyDown(event: KeyboardEvent) {
    if (this.lookupListVisibility && this.searchResult.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedIndex < this.searchResult.length - 1) {
            this.highlightedIndex++;
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          event.preventDefault();
          break;
        case 'Enter':
          if (this.highlightedIndex >= 0) {
            this.addLookup(this.searchResult[this.highlightedIndex].symbol);
          } else if (this.searchResult.length > 0) {
            this.addLookup(this.searchResult[0].symbol);
          }
          event.preventDefault();
          break;
      }
    }
  }

  addLookup(symbol: string) {
    this.hideLookupList();
    this.onClearSearch();
    this.toolsApi.setContext('dashboard');
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
  }
  async addToWatchlist() {
    const modal = await this.modalController.create({
      component: WatchListCreateAddModalComponent,
      componentProps: {
        selectedSymbol: this.symbol,
        selectedIds: this.userId,
      },
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.toolsApi.addWatchList(data).subscribe((response: any) => {
          if (response && response.message) {
            this.toastServ.presentToast(response.message, 'success');
          }
        });
        this.onStockDetails(this.symbol);
        this.watchlistCountShow = data.watchListCount;
      }
    });
    return await modal.present();
  }

  removeWatchList() {
    this.toolsApi.removeFromWatchList(this.symbol).subscribe((response: any) => {
      if (response) {
        this.toastServ.presentToast(response.message, 'success');
        this.onStockDetails(this.symbol);
      }
    });
  }
  onClearSearch() {
    this.searchText = null;
  }

  onListForWatchList() {
    this.toolsApi.getForWatchList().subscribe((response: any) => {
      if (response && response?.data) {
        this.watchList = response?.data;
      }
    });
  }

  onStockDetails(symbol) {
    //this.fromWatchlist = this.toolsApi.isFromWatchlist();
    this.context = this.toolsApi.getContext();
    this.toolsApi.getStockDetails(symbol).subscribe((response: any) => {
      if (response && response?.data) {
        this.selectedStockDetails = response?.data?.stockData;
        this.toolsNotesSlug = response?.data?.slug;
        this.watchlistCount = response?.data?.watchlistCount;
        this.buttonStateGroup.is_watch_list = this.selectedStockDetails.is_watch_list;
        this.loadSymbolGraph();
      }
    });
  }

  openLink(link: string): void {
    window.open(link, '_blank');
  }

  onSearchLookup(event: CustomEvent) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event.detail.value.trim().toLowerCase();
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.listOfLookup) {
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
      this.highlightedIndex = -1;
    } else {
      this.lookupListVisibility = false;
      this.selectedLookup = {
        symbol: '',
        name: '',
        exchange: '',
      };
    }
    this.onStockDetails(this.symbol);
  }

  async viewAlert(mode) {
    let watchListProps: any = {
      mode,
      componentType: 'TickerAlertsComponent',
    };
    this.menuService.openRightMenu(watchListProps);
  }

  hideLookupList() {
    this.lookupListVisibility = false;
    this.selectedLookup = {
      name: '',
      symbol: '',
      exchange: '',
    };
  }

  onGetLookup() {
    this.toolsApi.getCountryWiseStock('usa').subscribe((res: any) => {
      if (res && res?.data?.length) {
        this.listOfLookup = res.data;
      }
    });
  }

  // addLookup(symbol: string, name: string, exchange: string) {
  //   this.router.navigate([
  //     `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
  //   ]);
  //   this.onStockDetails(symbol);
  //   this.selectedLookup = { symbol, name, exchange };
  //   this.segment = 'stockInfo';
  // }

  ngAfterViewInit() {
    this.loadSymbolGraph();
  }

  loadSymbolGraph() {
    const hotlistSymbolElement = this?.hotlistsymbol?.nativeElement;
    if (hotlistSymbolElement) hotlistSymbolElement.innerHTML = '';
    let stockSymbol = this.selectedLookup.exchange + ':' + this.symbol;
    const script2 = this._renderer2.createElement('script');
    script2.type = `text/javascript`;
    script2.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script2.text = `
    {
      "autosize": true,
      "symbol": "${stockSymbol}",
      "interval": "D",
      "timezone": "Etc/UTC",
      "theme": "light",
      "style": "1",
      "locale": "in",
      "enable_publishing": false,
      "withdateranges": true,
      "allow_symbol_change": false,
      "calendar": false,
      "show_popup_button": true,
      "popup_width": "1000",
      "popup_height": "650",
      "support_host": "https://www.tradingview.com"
    }
    `;
    hotlistSymbolElement?.appendChild(script2);
  }

  convertMarketCapToWords(marketCap: number): string {
    if (!marketCap && marketCap !== 0) return '';
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(Math.abs(marketCap)) / 3);
    const newValue = (marketCap / Math.pow(10, magnitude * 3)).toFixed(2);
    return `${newValue} ${suffixes[magnitude]}`;
  }

  getHeaderClassTodayChange({ selectedStockDetails }): any {
    return {
      'custom-green': Number(selectedStockDetails?.regularMarketChange) >= 0,
      'custom-red': Number(selectedStockDetails?.regularMarketChange) < 0,
      'custom-black': Number(selectedStockDetails?.regularMarketChange) === 0,
    };
  }

  getHeaderClassPreChange({ selectedStockDetails }): any {
    return {
      'custom-green': Number(selectedStockDetails?.preMarketChangePercent) >= 0,
      'custom-red': Number(selectedStockDetails?.preMarketChangePercent) < 0,
      'custom-black': Number(selectedStockDetails?.preMarketChangePercent) === 0,
    };
  }
  getHeaderClassPostChange({ selectedStockDetails }): any {
    return {
      'custom-green': Number(selectedStockDetails?.postMarketChangePercent) >= 0,
      'custom-red': Number(selectedStockDetails?.postMarketChangePercent) < 0,
      'custom-black': Number(selectedStockDetails?.postMarketChangePercent) === 0,
    };
  }

  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
    if (this.segment == 'stockInfo') {
      this.cdr.detectChanges();
      this.loadSymbolGraph();
    }
  }

  onRecentNews(symbol, disableHttpLoader = 'true') {
    this.isLoading = true;
    this.toolsApi.getRecentNews(symbol, disableHttpLoader).subscribe(
      (result: any) => {
        const {
          data: { newsList = [] },
          meta,
        } = result;
        this.page = {
          offset: parseInt(meta?.page, 10) - 1,
          size: 10,
          totalElements: newsList.length,
          totalPages: 20,
        };
        this.selectedNews = newsList;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching recent news:', error);

        // Ensure isLoading is set to false even if there's an error
        this.isLoading = false;
      }
    );
  }

  destroyInterval() {
    if (this.interval) {
      this.timeOutPageTime = 0;
      clearInterval(this.interval);
      this.interval = 0;
    }
  }

  getSymbolWiseNotes(symbol, disableHttpLoader = 'true') {
    this.isLoading = true;
    this.toolsApi.getSymbolWiseNotes(symbol, disableHttpLoader).subscribe(
      (result: any) => {
        const { meta } = result;
        this.page = {
          offset: parseInt(meta?.page, 10) - 1,
          size: 10,
          totalElements: result.dataLength,
          totalPages: 20,
        };
        this.selectedNotes = result.data;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching recent Notes:', error);
        this.isLoading = false;
      }
    );
  }

  openRightMenuToolNotes(mode?: any, noteDetail?: any) {
    this.destroyInterval();
    this.mode = mode;
    const toolNotesData = {
      componentType: 'toolNotesComponent',
      symbol: this.selectedStockDetails?.symbol,
      slug: this.toolsNotesSlug,
      noteDetail: mode === 'edit' ? noteDetail : null,
    };
    this.menuService.openRightMenu(toolNotesData);
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }

  deleteNotes(row) {
    this.toastServ
      .confirmationAlert('Are you sure you want to delete this note?', 'Delete')
      .then((confirm) => {
        if (confirm) {
          this.toolsApi.deleteNote(row.slug).subscribe((response: any) => {
            this.toastServ.presentToast(response.message, 'success');
            this.getSymbolWiseNotes(this.selectedStockDetails.symbol);
          });
        }
      });
  }
}
