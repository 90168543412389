import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolsRoutingModule } from './tools-routing.module';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToolDashboardComponent } from './tool-dashboard/tool-dashboard.component';
import { WatchListComponent } from './watch-list/watch-list.component';
import { SetupScannerComponent } from './setup-scanner/setup-scanner.component';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [ToolDashboardComponent, WatchListComponent, SetupScannerComponent],
  imports: [CommonModule, ToolsRoutingModule, IonicModule, NgxDatatableModule, PipesModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToolsModule {}
