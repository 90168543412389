import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { RapidApiService } from 'src/app/services/rapid-api.service';

@Component({
  selector: 'app-ticker-details',
  templateUrl: './ticker-details.component.html',
  styleUrls: ['./ticker-details.component.scss'],
})
export class TickerDetailsComponent implements OnInit {
  currentRoute: any;
  public interval: any;
  slug: string;
  productSlug: string;
  toolSlug: string;
  stockCheck: number;
  row;
  allPosition = [];
  stockDetailRow;
  longPutPosition = [];
  weeklyPutPosition = [];
  rows = [];
  user: any;
  role: any;
  stockDetails: any;
  symbol: string;
  tradeLogo: any;
  mode: string;
  closeMenuSubs: Subscription;
  askBid: any;
  timeOutPageTime = 0;
  isIntervalLoader = false;
  showAllLongRows = false;
  showAllWeeklyRows = false;
  constructor(
    private location: Location,
    private menuService: commonRightMenuService,
    public storageServ: StorageService,
    private toolsApi: ToolServiceService,
    private activatedRoute: ActivatedRoute,
    private menuController: MenuController,
    public alertController: AlertController,
    private toastServ: ToastService,
    private rapidService: RapidApiService
  ) {
    this.slug = this.activatedRoute.snapshot.params.tickerSlug;
    this.productSlug = this.activatedRoute.snapshot.params.productSlug;
    this.toolSlug = this.activatedRoute.snapshot.params.toolSlug;
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {
    this.getportpholioData(this.slug);
    if (this.slug) {
      this.getPositionData(this.slug);
    }
    this.closeMenuSubs = this.menuService.getCloseMenuSubject().subscribe((res) => {
      if (this.slug) {
        this.getPositionData(this.slug);
        if (!this.interval) {
          this.startInterval();
        }
      }
    });
    if (!this.interval) {
      this.startInterval();
    }
  }

  get displayedRowsLong() {
    if (this.showAllLongRows) {
      return this.longPutPosition;
    }
    const defaultRows = this.longPutPosition.slice(0, 2);
    const lastRow = this.longPutPosition[this.longPutPosition.length - 1];
    if (!defaultRows.includes(lastRow)) {
      defaultRows.push(lastRow);
    }
    return defaultRows;
  }

  get displayedRowsWeekly() {
    if (this.showAllWeeklyRows) {
      return this.weeklyPutPosition;
    }
    const defaultRows = this.weeklyPutPosition.slice(0, 2);
    const lastRow = this.weeklyPutPosition[this.weeklyPutPosition.length - 1];
    if (!defaultRows.includes(lastRow)) {
      defaultRows.push(lastRow);
    }
    return defaultRows;
  }

  toggleLongShowMore() {
    this.showAllLongRows = !this.showAllLongRows;
  }

  toggleWeeklyShowMore() {
    this.showAllWeeklyRows = !this.showAllWeeklyRows;
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.checkforPosition();
    }, 300000);
  }

  destroyInterval() {
    if (this.interval) {
      this.timeOutPageTime = 0;
      clearInterval(this.interval);
      this.interval = 0;
    }
  }

  async checkForPageTimeout() {
    if (this.timeOutPageTime >= 2) {
      this.destroyInterval();
      this.timeOutPageTime = 0;
      const alert = await this.alertController.create({
        cssClass: 'custom-alert-popup',
        subHeader: 'Your page has timed out. Please refresh the page and try again.',
        buttons: [
          {
            text: 'Reload',
            cssClass: 'delete-button',
            handler: () => {
              this.startInterval();
              this.getPositionData(this.slug);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'ghost-button',
          },
        ],
      });
      await alert.present();
      return false;
    } else {
      return true;
    }
  }

  async checkforPosition() {
    const isInterval = await this.checkForPageTimeout();
    if (isInterval) {
      this.timeOutPageTime += 1;
      const positions = [...this.row?.long, ...this.row?.weekly];
      if (positions?.length) {
        let minExpiration = new Date(positions[0].expirationPeriod);
        let maxExpiration = new Date(positions[0].expirationPeriod);
        let minStrikeBought = this.getStrike(positions[0]);
        let maxStrikeBought = this.getStrike(positions[0]);
        positions.forEach((position) => {
          const expirationDate = new Date(position.expirationPeriod);
          if (expirationDate < minExpiration) {
            minExpiration = expirationDate;
          }
          if (expirationDate > maxExpiration) {
            maxExpiration = expirationDate;
          }
          const strikeBought = this.getStrike(position);
          if (strikeBought < minStrikeBought) {
            minStrikeBought = strikeBought;
          }
          if (strikeBought > maxStrikeBought) {
            maxStrikeBought = strikeBought;
          }
        });
        const payload = {
          symbol: this.stockDetailRow.symbol,
          type: 'Put',
          min_expiry: new Date(minExpiration).toISOString().substring(0, 10),
          max_expiry: new Date(maxExpiration).toISOString().substring(0, 10),
          min_strike: minStrikeBought,
          max_strike: maxStrikeBought,
        };
        // check and prepare payload accordingly
        this.rapidService.getExpirationDate(payload).subscribe(async (res) => {
          this.askBid = res;
          if (this.askBid?.length) {
            let isSync: any = false;
            for (const position of positions) {
              const askValue = this.askBid.find((ask) => {
                const expiration = moment(position?.expirationPeriod).format('YYYY-MM-DD');
                const strikePrice = position?.strikeBought || position?.strikeSold;
                const isSame = moment(expiration, 'YYYY-MM-DD').isSame(
                  moment(ask?.Expiration, 'YYYY-MM-DD')
                );
                if (isSame && strikePrice === ask.Strike) {
                  return ask;
                }
              });
              const askBidValue =
                position.positionType === 'weekly' ? askValue?.Ask : askValue?.Bid;
              if (askBidValue) {
                let payload = null;
                if (position?.positionStatus === 'open') {
                  const clone = {
                    tickerSymbol: this.stockDetailRow.symbol,
                    tickerSlug: this.slug,
                    positionType: position.positionType,
                    positionStatus: position.positionStatus,
                    optionDirection: '',
                    expirationPeriod: position.expirationPeriod,
                    numberOfContracts: position.numberOfContracts,
                    tradeProfitLoss: 0,
                    exitPremium: position.positionType === 'long' ? askValue?.Bid : askValue.Ask,
                    profitLossPercentage: 0,
                    underlyingStock: position.underlyingStock,
                  };
                  // for long position
                  if (position.positionType === 'long') {
                    payload = {
                      ...clone,
                      longPut: {
                        datePurchased: position.datePurchased,
                        premiumPaid: position.premiumPaid,
                        syntheticStrike: 0,
                        averagePremiumLoss: 0,
                        strikeBought: position.strikeBought,
                      },
                    };

                    const response = await this.toolsApi
                      .updatePosition(position.slug, payload, 'true')
                      .toPromise();
                    isSync = response ? true : false;
                  }
                  // for weekly position
                  if (position.positionType === 'weekly') {
                    payload = {
                      ...clone,
                      weeklyPut: {
                        dateSold: position.dateSold,
                        strikeSold: position.strikeSold,
                        premiumSold: position.premiumSold,
                        premiumAsPercentageOfStock: 0,
                        avgPremiumEarned: 0,
                      },
                    };
                    const response = await this.toolsApi
                      .updatePosition(position.slug, payload, 'true')
                      .toPromise();
                    isSync = response ? true : false;
                  }
                }
              }
            }
            if (isSync) {
              this.isIntervalLoader = true;
              setTimeout(() => {
                this.getPositionData(this.slug, 'true', 'false');
              }, 3000);
            }
          }
        });
      }
    }
  }

  checkForLoader(row) {
    let isLoader = false;
    const expiration: string = moment(row?.expirationPeriod).format('YYYY-MM-DD');
    const currentDate: string = moment().format('YYYY-MM-DD');
    const expirationDate: moment.Moment = moment(expiration, 'YYYY-MM-DD');
    const currentDateObj: moment.Moment = moment(currentDate, 'YYYY-MM-DD');
    if (
      row?.expirationPeriod &&
      expirationDate.isSameOrAfter(currentDateObj, 'day') &&
      row?.positionStatus === 'open'
    ) {
      isLoader = true;
    }
    return isLoader;
  }

  getportpholioData(data) {
    const { accountSize, weekly, long } = data;
    let weeklyLastVal = null;
    let longLastVal = null;
    let totalContracts = 0;
    let longStrikeBought = 0;
    let weeklyStrikeBought = 0;

    if (weekly && weekly.length) {
      const openWeeklyPositions = weekly.filter((w) => w.positionStatus === 'open');
      weeklyLastVal =
        openWeeklyPositions && openWeeklyPositions.length
          ? openWeeklyPositions[openWeeklyPositions.length - 1]
          : {};
      /*    totalContracts = weekly.reduce(function (accumulator, currentValue) {
           return accumulator + currentValue.numberOfContracts;
         }, 0); */
    }

    if (long && long.length) {
      const openLongPositions = long.filter((l) => l.positionStatus === 'open');
      longLastVal =
        openLongPositions && openLongPositions.length
          ? openLongPositions[openLongPositions.length - 1]
          : {};
      totalContracts += openLongPositions.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.numberOfContracts;
      }, 0);
    }

    if (weeklyLastVal) {
      weeklyStrikeBought = weeklyLastVal.strikeSold;
    }
    if (longLastVal) {
      longStrikeBought = longLastVal.strikeBought;
    }
    const margin = (weeklyStrikeBought - longStrikeBought) * totalContracts * 100;
    this.row = {
      ...this.row,
      margin: margin || 0,
      portpholioRisk: accountSize && margin ? (margin / accountSize) * 100 : 0,
    };
  }

  getPositionData(slug, isLoader?, isTimerReset = 'true') {
    this.toolsApi.getPositionData(slug, isLoader).subscribe(
      (res: any) => {
        if (res.data && res.data?.position)
          this.row = {
            ...res.data.position,
          };
        if (res.data && res.data?.stockDetail)
          this.stockDetailRow = {
            ...res.data.stockDetail,
          };
        this.getportpholioData(res.data.position);
        this.longPutPosition = [
          ...res.data.position.long,
          ...(res.data.position.long.length > 0
            ? [
                {
                  isTotalCumulativeProfitPrice: true,
                  cumulativeProfitLoss:
                    res?.data?.position?.long[res?.data?.position?.long.length - 1]
                      ?.cumulativeProfitLoss || 0,
                },
              ]
            : []),
        ];
        this.weeklyPutPosition = [
          ...res.data.position.weekly,
          ...(res.data.position.weekly.length > 0
            ? [
                {
                  isTotalCumulativeProfitPrice: true,
                  cumulativeProfitLoss:
                    res?.data?.position?.weekly[res?.data?.position?.weekly.length - 1]
                      ?.cumulativeProfitLoss || 0,
                },
              ]
            : []),
        ];
        // turn of the interval loader
        this.isIntervalLoader = false;
        // reset timer when getPositionData function is not calling from the checkforPosition() function
        if (isTimerReset === 'true') this.timeOutPageTime = 0;
      },
      (error) => {
        if (error || error?.message) {
          this.isIntervalLoader = false;
        }
      }
    );
  }

  getStrike(position) {
    let strike = 0.0;
    if (position?.strikeSold) {
      strike = position?.strikeSold;
    } else if (position?.strikeBought) {
      strike = position?.strikeBought;
    }
    return strike;
  }

  back() {
    this.getPositionData(this.slug);
    this.location.back();
  }
  openRightMenu() {
    // destroy interval
    this.destroyInterval();
    const alertData = {
      componentType: 'alertComponent',
      slug: this.slug,
      symbol: this.stockDetailRow?.symbol,
      stockPrice: this.stockDetailRow?.stockPrice,
      todayPnL: this.stockDetailRow?.regularMarketChange,
    };
    this.menuService.openRightMenu(alertData); // Trigger the menu open event
  }

  openRightMenuNotes() {
    // destroy interval
    this.destroyInterval();
    const notesData = {
      componentType: 'notesComponent',
      slug: this.slug,
      symbol: this.stockDetailRow?.symbol,
    };
    this.menuService.openRightMenu(notesData);
  }
  async positionAddUpdate(type, mode, data?) {
    // destroy interval
    this.destroyInterval();
    this.mode = mode;
    const today = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    let positionProps: any = {
      mode,
      formType: type,
      symbol: this.stockDetailRow?.symbol,
      stockPrice: this.stockDetailRow?.stockPrice,
      slug: this.slug,
      componentType: 'positionComponent',
      isPositionExpired: false,
      todayDate: today,
    };

    const positionList = type === 'long' ? [...this.longPutPosition] : [...this.weeklyPutPosition];

    if (mode == 'edit') {
      positionProps.positionList = positionList;
      positionProps.editData = data;
      positionProps.isPositionExpired =
        data && data.expirationPeriod
          ? new Date(data.expirationPeriod).getTime() <= new Date().getTime()
          : true;
    } else {
      positionProps.positionList = positionList;
    }
    this.menuService.openRightMenu(positionProps);
  }

  calculateTotalLong(): number {
    let total = 0;
    if (this.row && this.row.long && this.row.long.length > 0) {
      total += this.row.long.reduce((acc, item) => acc + item.cumulativeProfitLoss, 0);
    }
    return total;
  }

  calculateTotalWeekly(): number {
    let total = 0;
    if (this.row && this.row.weekly && this.row.weekly.length > 0) {
      total += this.row.weekly.reduce((acc, item) => acc + item.cumulativeProfitLoss, 0);
    }
    return total;
  }

  calculatePnL(): number {
    let totalPnL = 0;
    let longPosition = this.row?.long?.length
      ? this.row.long[this.row.long.length - 1]?.cumulativeProfitLoss || 0
      : 0;
    let weeklyPosition = this.row?.weekly?.length
      ? this.row.weekly[this.row.weekly.length - 1]?.cumulativeProfitLoss || 0
      : 0;
    totalPnL = longPosition + weeklyPosition;
    return totalPnL;
  }

  getHeaderClassProfitLoss({ row }): any {
    return {
      'custom-cell-green': Number(row.profitLossPercentage) >= 0,
      'custom-cell-red': Number(row.profitLossPercentage) < 0,
    };
  }

  getHeaderClassTodayChange({ stockDetailRow }): any {
    return {
      'custom-green': Number(stockDetailRow?.regularMarketChange) >= 0,
      'custom-red': Number(stockDetailRow?.regularMarketChange) < 0,
      'custom-black': Number(stockDetailRow?.regularMarketChange) === 0,
    };
  }

  getHeaderClassPositionPnL({ row }): any {
    return {
      'custom-cell-green': Number(row.totalProfitLoss) >= 0,
      'custom-cell-red': Number(row.totalProfitLoss) < 0,
      'custom-cell-black': Number(row.totalProfitLoss) === 0,
    };
  }

  getClassForEarningDays({ stockDetailRow }) {
    return {
      'custom-cell-green': stockDetailRow?.daysDifference >= 45,
      'custom-cell-darkgoldenrod':
        stockDetailRow?.daysDifference >= 30 && stockDetailRow?.daysDifference < 45,
      'custom-cell-red': stockDetailRow?.daysDifference < 30,
      'custom-cell-yellow': stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate,
    };
  }

  getColorBasedOnStatus(row): string {
    return row === 'open' ? 'darkgoldenrod' : ''; // Set the desired color for 'open' status
  }

  async openConfirmationDialog(row, type) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      subHeader: 'Are you sure you want to delete this Leg?',
      buttons: [
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.onTrash(row, type);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
        },
      ],
    });
    await alert.present();
  }

  onTrash(row, type) {
    this.toolsApi.deleteLegs(row._id, type).subscribe((response: any) => {
      this.toastServ.presentToast(response.message, 'success');
      this.mode = 'delete';
      this.getPositionData(this.slug);
    });
  }

  ngOnDestroy() {
    this.destroyInterval();
    if (this.closeMenuSubs) {
      this.closeMenuSubs.unsubscribe();
    }
  }
}
