import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import {
  CHANGE,
  COUNTRY,
  DAVGCHG200,
  DAVGCHG50,
  EARNINGS,
  FILTERLIST,
  INDUSTRY,
  MARKET_CAP,
  PRICE,
  SECTOR,
  VOLUME,
  WHIGHCHG52,
} from 'src/app/utils/constant';

@Component({
  selector: 'app-filter-setup-scanner-list',
  templateUrl: './filter-setup-scanner-list.component.html',
  styleUrls: ['./filter-setup-scanner-list.component.scss'],
})
export class FilterSetupScannerListComponent implements OnInit {
  @Input() setupScannerFilterData: any;
  showForm = false;
  selectedMarketCap: string;
  selectedPrice: string;
  selectedChange: string;
  selectedVolume: string;
  selectedEarnings: string;
  selectedCountry: string;
  selectedIndustry: string;
  selectedSector: string;
  selected50DAvgChg: string;
  selected200DAvgChg: string;
  selected52WHighChg: string;
  filterDataObject = {};
  MARKET_CAP = MARKET_CAP;
  PRICE = PRICE;
  CHANGE = CHANGE;
  VOLUME = VOLUME;
  EARNINGS = EARNINGS;
  FILTERLIST = FILTERLIST;
  COUNTRY = COUNTRY;
  INDUSTRY = INDUSTRY;
  SECTOR = SECTOR;
  DAVGCHG50 = DAVGCHG50;
  DAVGCHG200 = DAVGCHG200;
  WHIGHCHG52 = WHIGHCHG52;
  selectedFilter: string = '';
  public page = new Page();
  rows: any;
  constructor(
    public menuController: MenuController,
    private tradeToolsService: TradeToolsService,
    private toastServ: ToastService,
    private menuService: commonRightMenuService
  ) {}

  ngOnInit() {}

  getFilterWiseShow(filter: string) {
    this.selectedFilter = filter;
    this.showForm = !this.showForm;
  }

  goBack() {
    this.showForm = false;
  }

  cancel(isNotify = 'true') {
    this.showForm = false;
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }

  isAnyFilterApplied() {
    return (
      this.selectedPrice ||
      this.selectedVolume ||
      this.selectedEarnings ||
      this.selectedMarketCap ||
      this.selectedChange ||
      this.selectedCountry ||
      this.selectedIndustry ||
      this.selectedSector ||
      this.selected50DAvgChg ||
      this.selected200DAvgChg ||
      this.selected52WHighChg
    );
  }

  clearFilters() {
    this.selectedPrice = null;
    this.selectedVolume = null;
    this.selectedEarnings = null;
    this.selectedMarketCap = null;
    this.selectedChange = null;
    this.selectedCountry = null;
    this.selectedIndustry = null;
    this.selectedSector = null;
    this.selected50DAvgChg = null;
    this.selected200DAvgChg = null;
    this.selected52WHighChg = null;
  }

  get appliedFilterCount(): number {
    let count = 0;
    if (this.selectedPrice) count++;
    if (this.selectedVolume) count++;
    if (this.selectedEarnings) count++;
    if (this.selectedMarketCap) count++;
    if (this.selectedChange) count++;
    return count;
  }

  applyFilter() {
    let filterObject = { page: 1, pageSize: 50 };
    if (this.selectedPrice && this.selectedPrice !== 'any') {
      filterObject['price'] = this.selectedPrice;
    }

    if (this.selectedVolume && this.selectedVolume !== 'any') {
      filterObject['volume'] = this.selectedVolume;
    }

    if (this.selectedEarnings && this.selectedEarnings !== 'any') {
      filterObject['earnings'] = this.selectedEarnings;
    }

    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') {
      filterObject['marketCap'] = this.selectedMarketCap;
    }

    if (this.selectedChange && this.selectedChange !== 'any') {
      filterObject['changes'] = this.selectedChange;
    }

    if (this.selectedCountry && this.selectedCountry !== 'any') {
      filterObject['country'] = this.selectedCountry;
    }

    if (this.selectedIndustry && this.selectedIndustry !== 'any') {
      filterObject['industry'] = this.selectedIndustry;
    }

    if (this.selectedSector && this.selectedSector !== 'any') {
      filterObject['sector'] = this.selectedSector;
    }

    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') {
      filterObject['50DAvgChg'] = this.selected50DAvgChg;
    }

    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') {
      filterObject['200DAvgChg'] = this.selected200DAvgChg;
    }
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') {
      filterObject['52WHighChg'] = this.selected52WHighChg;
    }

    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
      });
  }

  applyFilterList() {
    let filterObject = { page: 1, pageSize: 50 };
    filterObject['price'] =
      this.selectedPrice && this.selectedPrice !== 'any' ? this.selectedPrice : null;
    filterObject['volume'] =
      this.selectedVolume && this.selectedVolume !== 'any' ? this.selectedVolume : null;
    filterObject['earnings'] =
      this.selectedEarnings && this.selectedEarnings !== 'any' ? this.selectedEarnings : null;
    filterObject['marketCap'] =
      this.selectedMarketCap && this.selectedMarketCap !== 'any' ? this.selectedMarketCap : null;
    filterObject['changes'] =
      this.selectedChange && this.selectedChange !== 'any' ? this.selectedChange : null;
    filterObject['country'] =
      this.selectedCountry && this.selectedCountry !== 'any' ? this.selectedCountry : null;
    filterObject['industry'] =
      this.selectedIndustry && this.selectedIndustry !== 'any' ? this.selectedIndustry : null;
    filterObject['sector'] =
      this.selectedSector && this.selectedSector !== 'any' ? this.selectedSector : null;
    filterObject['50DAvgChg'] =
      this.selected50DAvgChg && this.selected50DAvgChg !== 'any' ? this.selected50DAvgChg : null;
    filterObject['200DAvgChg'] =
      this.selected200DAvgChg && this.selected200DAvgChg !== 'any' ? this.selected200DAvgChg : null;
    filterObject['52WHighChg'] =
      this.selected52WHighChg && this.selected52WHighChg !== 'any' ? this.selected52WHighChg : null;
    const filterCount = this.calculateFilterCount();
    filterObject['filterCount'] = filterCount;
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.menuController.close();
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.menuService.triggerChildResetFilter({ ...result, filterCount });
      });
  }

  private calculateFilterCount(): number {
    let count = 0;
    if (this.selectedPrice && this.selectedPrice !== 'any') count++;
    if (this.selectedVolume && this.selectedVolume !== 'any') count++;
    if (this.selectedEarnings && this.selectedEarnings !== 'any') count++;
    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') count++;
    if (this.selectedChange && this.selectedChange !== 'any') count++;
    if (this.selectedCountry && this.selectedCountry !== 'any') count++;
    if (this.selectedIndustry && this.selectedIndustry !== 'any') count++;
    if (this.selectedSector && this.selectedSector !== 'any') count++;
    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') count++;
    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') count++;
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') count++;
    return count;
  }

  clearSpecificFilter(type) {
    if (type == 'price') this.selectedPrice = null;
    if (type == 'volume') this.selectedVolume = null;
    if (type == 'earnings') this.selectedEarnings = null;
    if (type == 'marketcap') this.selectedMarketCap = null;
    if (type == 'changes(%)') this.selectedChange = null;
    this.selectedCountry = null;
    this.selectedIndustry = null;
    this.selectedSector = null;
    this.selected50DAvgChg = null;
    this.selected200DAvgChg = null;
    this.selected52WHighChg = null;
  }
}
