<div>
  <ion-header class="ion-no-border header-top-custom">
    <ion-toolbar>
      <div class="close-heading-block">
        <div class="page-heading">
          <div class="filter-ion-icon">
            <ion-icon name="options-outline" class="filter-icon"></ion-icon>
          </div>
          <h2>Filters</h2>
        </div>
        <ion-buttons slot="end">
          <ion-button (click)="cancel()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
  </ion-header>
</div>

<div *ngIf="!showForm">
  <!-- <ion-searchbar -->
  <!-- search-icon="search-outline" -->
  <!-- debounce="1000" -->
  <!-- id="search" -->
  <!-- class="ion-no-padding filter-search" -->
  <!-- placeholder="Search help articles" -->
  <!-- </ion-searchbar> -->
  <div class="popular-block">
    <div *ngIf="isAnyFilterApplied()" class="back-to">
      <a (click)="clearFilters()"
        ><ion-icon slot="icon-only" name="close-outline"></ion-icon>Clear All Filter</a
      >
    </div>
    <div class="page-heading">
      <h5>Most Used</h5>
    </div>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('price')">
        <div class="filter-data">
          <span> Price </span>
          <span
            *ngIf="selectedPrice"
            (click)="clearSpecificFilter('price'); $event.stopPropagation()"
            class="filter-selector"
          >
            {{ selectedPrice }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon>
          </span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('volume')">
        <div class="filter-data">
          <span> Volume </span>
          <span
            *ngIf="selectedVolume"
            (click)="clearSpecificFilter('volume'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedVolume }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('earnings')">
        <div class="filter-data">
          <span>Earnings</span>
          <span
            *ngIf="selectedEarnings"
            (click)="clearSpecificFilter('earnings'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedEarnings }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change">
        <div class="filter-data">
          <span> Has Weekly Options ? </span>
          <span class="filter-selector">
            Yes
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('marketcap')">
        <div class="filter-data">
          <span> Market Cap </span>
          <span
            *ngIf="selectedMarketCap"
            (click)="clearSpecificFilter('marketcap'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedMarketCap }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('changes(%)')">
        <div class="filter-data">
          <span> Change (%) </span>
          <span
            *ngIf="selectedChange"
            (click)="clearSpecificFilter('changes(%)'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChange }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
  </div>
  <div class="sticky-button">
    <ion-button
      type="submit"
      color="primary"
      fill="solid"
      style="width: 100%"
      (click)="applyFilterList()"
    >
      Apply
    </ion-button>
  </div>
  <!--  -->
  <!-- <div class="popular-block"> -->
  <!-- <div class="page-heading"> -->
  <!-- <h5>Additional Filters</h5> -->
  <!-- </div> -->
  <!--  -->
  <!-- <ul class="content-custom list-content-block"> -->
  <!-- <li class="background-change" (click)="getFilterWiseShow('country')"> -->
  <!-- <div class="filter-data"> -->
  <!-- Country -->
  <!-- <span -->
  <!-- *ngIf="selectedCountry" -->
  <!-- (click)="clearSpecificFilter('country'); $event.stopPropagation()" -->
  <!-- class="filter-selector" -->
  <!-- >{{ selectedCountry }} -->
  <!-- <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon -->
  <!-- ></span> -->
  <!-- </div> -->
  <!-- <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon> -->
  <!-- </li> -->
  <!-- </ul> -->
  <!-- <ul class="content-custom list-content-block"> -->
  <!-- <li class="background-change" (click)="getFilterWiseShow('industry')"> -->
  <!-- <div class="filter-data"> -->
  <!-- Industry -->
  <!-- <span -->
  <!-- *ngIf="selectedIndustry" -->
  <!-- (click)="clearSpecificFilter('industry'); $event.stopPropagation()" -->
  <!-- class="filter-selector" -->
  <!-- >{{ selectedIndustry }} -->
  <!-- <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon -->
  <!-- ></span> -->
  <!-- </div> -->
  <!-- <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon> -->
  <!-- </li> -->
  <!-- </ul> -->
  <!-- <ul class="content-custom list-content-block"> -->
  <!-- <li class="background-change" (click)="getFilterWiseShow('sector')"> -->
  <!-- <div class="filter-data"> -->
  <!-- Sector -->
  <!-- <span -->
  <!-- *ngIf="selectedSector" -->
  <!-- (click)="clearSpecificFilter('sector'); $event.stopPropagation()" -->
  <!-- class="filter-selector" -->
  <!-- >{{ selectedSector }} -->
  <!-- <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon -->
  <!-- ></span> -->
  <!-- </div> -->
  <!-- <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon> -->
  <!-- </li> -->
  <!-- </ul> -->
  <!-- <ul class="content-custom list-content-block"> -->
  <!-- <li class="background-change" (click)="getFilterWiseShow('50DAvgChg')"> -->
  <!-- <div class="filter-data"> -->
  <!-- 50D Avg Chg -->
  <!-- <span -->
  <!-- *ngIf="selected50DAvgChg" -->
  <!-- (click)="clearSpecificFilter('50DAvgChg'); $event.stopPropagation()" -->
  <!-- class="filter-selector" -->
  <!-- >{{ selected50DAvgChg }} -->
  <!-- <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon -->
  <!-- ></span> -->
  <!-- </div> -->
  <!-- <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon> -->
  <!-- </li> -->
  <!-- </ul> -->
  <!-- <ul class="content-custom list-content-block"> -->
  <!-- <li class="background-change" (click)="getFilterWiseShow('200DAvgChg')"> -->
  <!-- <div class="filter-data"> -->
  <!-- 200D Avg Chg -->
  <!-- <span -->
  <!-- *ngIf="selected200DAvgChg" -->
  <!-- (click)="clearSpecificFilter('200DAvgChg'); $event.stopPropagation()" -->
  <!-- class="filter-selector" -->
  <!-- >{{ selected200DAvgChg }} -->
  <!-- <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon -->
  <!-- ></span> -->
  <!-- </div> -->
  <!-- <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon> -->
  <!-- </li> -->
  <!-- </ul> -->
  <!-- <ul class="content-custom list-content-block"> -->
  <!-- <li class="background-change" (click)="getFilterWiseShow('52WHighChg')"> -->
  <!-- <div class="filter-data"> -->
  <!-- 52W High Chg -->
  <!-- <span -->
  <!-- *ngIf="selected52WHighChg" -->
  <!-- (click)="clearSpecificFilter('52WHighChg'); $event.stopPropagation()" -->
  <!-- class="filter-selector" -->
  <!-- >{{ selected52WHighChg }} -->
  <!-- <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon -->
  <!-- ></span> -->
  <!-- </div> -->
  <!-- <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon> -->
  <!-- </li> -->
  <!-- </ul> -->
  <!-- </div> -->
</div>

<div *ngIf="showForm">
  <div class="back-to">
    <a (click)="goBack()"
      ><ion-icon class="icon-color" name="chevron-back-outline"></ion-icon>Back To Filter</a
    >
  </div>
  <div [ngSwitch]="selectedFilter">
    <div class="input-container" *ngSwitchCase="'price'">
      <ion-label>Price</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedPrice"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let PRICE of PRICE" [value]="PRICE.value"
            >{{ PRICE.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'volume'">
      <ion-label>Volume</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedVolume"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let VOLUME of VOLUME" [value]="VOLUME.value"
            >{{ VOLUME.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'earnings'">
      <ion-label>Earnings</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedEarnings"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let EARNINGS of EARNINGS" [value]="EARNINGS.value"
            >{{ EARNINGS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'marketcap'">
      <ion-label>Market Cap</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedMarketCap"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let MARKET_CAP of MARKET_CAP" [value]="MARKET_CAP.value"
            >{{ MARKET_CAP.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'changes(%)'">
      <ion-label>Change (%)</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let CHANGE of CHANGE" [value]="CHANGE.value"
            >{{ CHANGE.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>

    <div class="input-container" *ngSwitchCase="'country'">
      <ion-label>Country</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedCountry"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let COUNTRY of COUNTRY" [value]="COUNTRY.value"
            >{{ COUNTRY.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'industry'">
      <ion-label>Industry</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedIndustry"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let INDUSTRY of INDUSTRY" [value]="INDUSTRY.value"
            >{{ INDUSTRY.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'sector'">
      <ion-label>Sector</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedSector"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let SECTOR of SECTOR" [value]="SECTOR.value"
            >{{ SECTOR.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'50DAvgChg'">
      <ion-label>50D Avg Chg</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selected50DAvgChg"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let DAVGCHG50 of DAVGCHG50" [value]="DAVGCHG50.value"
            >{{ DAVGCHG50.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'200DAvgChg'">
      <ion-label>200D Avg Chg</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selected200DAvgChg"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let DAVGCHG200 of DAVGCHG200" [value]="DAVGCHG200.value"
            >{{ DAVGCHG200.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'52WHighChg'">
      <ion-label>52W High Chg</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selected52WHighChg"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let WHIGHCHG52 of WHIGHCHG52" [value]="WHIGHCHG52.value"
            >{{ WHIGHCHG52.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="row choose-button">
      <ion-button type="submit" fill="solid" class="filter-cancel" (click)="goBack()">
        Cancel
      </ion-button>
      <ion-button type="submit" color="primary" fill="solid" (click)="applyFilter()">
        Apply
      </ion-button>
    </div>
  </div>
</div>
