<ion-content class="ion-padding" appScrollbar>
  <ion-row class="setup-nav" *ngIf="!hideCryptoDetails">
    <ion-col size="8">
      <div>
        <ol class="cd-breadcrumb custom-separator custom-icons">
          <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
          <li>
            <a routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}">Setup-Scanner</a>
          </li>
          <li class="current">Weekly Option Stocks</li>
        </ol>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="setup-nav" *ngIf="hideCryptoDetails">
    <ion-col size="8">
      <div>
        <ol class="cd-breadcrumb custom-separator custom-icons">
          <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
          <li class="current">Upcoming Earnings</li>
        </ol>
      </div>
    </ion-col>
  </ion-row>
  <div class="page-heading" *ngIf="hideCryptoDetails">
    <span> Upcoming Earnings</span>
  </div>
  <ion-grid class="icon-grid-block crypto step-scanner" *ngIf="hideCryptoDetails">
    <ion-col class="cryptos">
      <ion-card class="crypto-info">
        <ion-row>
          <ion-col class="setup-scanner-dropdown">
            <ion-searchbar
              debounce="1000"
              search-icon="none"
              id="search"
              class="search ion-no-padding setup-search"
              (ionClear)="onClearSearch($event)"
              (ionChange)="onSearch($event)"
              placeholder="Search by Ticker Symbol"
            >
            </ion-searchbar>
            <ion-button fill="outline" (click)="openRightMenu()">
              <ion-icon slot="start" name="options-outline"></ion-icon>
              Filters
              <span class="number-round" *ngIf="filterCountShow">{{
                filterCountShow == 0 ? '' : filterCountShow
              }}</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-grid>

  <div class="page-heading" *ngIf="!hideCryptoDetails">
    <span class="back-trade" routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}">
      < Back to Setup Scanner</span
    >
  </div>
  <ion-grid class="icon-grid-block crypto step-scanner" *ngIf="!hideCryptoDetails">
    <ion-col class="cryptos">
      <ion-card class="crypto-info">
        <div class="crypto-details">
          <div class="crypto-details-info">
            <span class="show-watchlist-name">Weekly Option Stocks</span>
            <p class="show-description">These are the stocks that have weekly options.</p>
          </div>
        </div>
        <ion-row>
          <ion-col class="setup-scanner-dropdown">
            <ion-searchbar
              debounce="1000"
              search-icon="none"
              id="search"
              class="search ion-no-padding setup-search"
              (ionClear)="onClearSearch($event)"
              (ionChange)="onSearch($event)"
              placeholder="Search by Ticker Symbol"
            >
            </ion-searchbar>
            <ion-button fill="outline" (click)="openRightMenu()">
              <ion-icon slot="start" name="options-outline"></ion-icon>
              Filters
              <span class="number-round" *ngIf="filterCountShow">{{
                filterCountShow == 0 ? '' : filterCountShow
              }}</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-grid>
  <div>
    <div class="loading-spinner" *ngIf="isLoading"></div>
    <ngx-datatable
      #externalSpendTable
      *ngIf="!isLoading"
      class="user-view material"
      (page)="applyFilter($event)"
      [rows]="rows"
      [columnMode]="'force'"
      [rowHeight]="'45'"
      [headerHeight]="50"
      [footerHeight]="40"
      [externalPaging]="true"
      [scrollbarH]="true"
      [count]="page.totalElements"
      [offset]="page.offset"
      [limit]="page.size"
    >
      <ngx-datatable-column [width]="30" [width]="60" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>TICKER</ng-template>
        <ng-template ngx-datatable-cell-template let-symbol="row.symbol">
          <span [ngClass]="{ 'green-symbol': true }" (click)="viewSymbol(symbol)">
            {{ symbol }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="200" [width]="270" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>COMPANY</ng-template>
        <ng-template ngx-datatable-cell-template let-company="row.company">
          {{ company }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="200" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>INDUSTRY</ng-template>
        <ng-template ngx-datatable-cell-template let-industry="row.industry">
          {{ industry }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="200" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>SECTOR</ng-template>
        <ng-template ngx-datatable-cell-template let-sector="row.sector">
          {{ sector }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="70" [width]="100" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataMarketCap()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>MARKET CAP</span>
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-marketCap="row.marketCap">
          {{ marketCap }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="30" [width]="60" [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataPrice()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>PRICE</span>
          </div></ng-template
        >
        <ng-template ngx-datatable-cell-template let-regularMarketPrice="row.regularMarketPrice">
          {{ regularMarketPrice }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [cellClass]="getHeaderClassChangePercent"
        [width]="70"
        [width]="100"
        [resizeable]="false"
        [sortable]="false"
      >
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataChange()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>CHANGE</span>
          </div></ng-template
        >
        <ng-template
          ngx-datatable-cell-template
          let-regularMarketChangePercent="row.regularMarketChangePercent"
        >
          {{ regularMarketChangePercent | number : '1.2-2' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="70" [sortable]="false" [width]="100" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="sorting-data" (click)="sortDataVolume()">
            <div class="sort-arrows">
              <ion-icon name="caret-up-outline"></ion-icon>
              <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
            </div>
            <span>VOLUME</span>
          </div></ng-template
        >
        <ng-template ngx-datatable-cell-template let-regularMarketVolume="row.regularMarketVolume">
          {{ regularMarketVolume }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template> ACTION </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div>
            <ion-buttons>
              <ion-button fill="clear" (click)="toggleDropdown($event, row)">
                <ion-icon name="ellipsis-vertical"> </ion-icon>
              </ion-button>
              <div *ngIf="isDropdownOpen && selectedRow === row" class="dropdown-menu">
                <ion-item (click)="addToWatchlist(row.symbol)" id="watchlist-list-area"
                  >Add to Watchlist
                </ion-item>
              </div>
            </ion-buttons>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ion-content>
