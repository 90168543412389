import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { baseUrl } from '../../environments/environment';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ToolServiceService {
  private context: 'dashboard' | 'watchlist' | 'setup-scanner' = 'dashboard';
  constructor(private http: HttpClient, private storagesrv: StorageService) {}

  getProductList() {
    return this.http.get(`${baseUrl}/trade/tracker-products`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
  addNewTrade(formData) {
    return this.http.post(`${baseUrl}/trade`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
  getTradeList(productSlug, searchText, status): Observable<any> {
    const qp = new URLSearchParams();

    if (searchText) {
      qp.set('keyword', searchText);
    }
    if (status) {
      qp.set('status', status);
    }

    return this.http.get(`${baseUrl}/trade/ticker-list/${productSlug}?` + qp.toString(), {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getStrike(symbol, expiration?): Observable<any> {
    const qp = new URLSearchParams();
    qp.set('symbol', symbol);

    if (expiration) {
      qp.set('expiration', expiration);
    }
    return this.http.get(`${baseUrl}/trade/getExpirationDate?` + qp.toString(), {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getPositionData(slug, disableHttpLoader = 'false'): Observable<any> {
    const qp = new URLSearchParams();
    return this.http.get(`${baseUrl}/trade/position/${slug}` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  createLeg(formData) {
    return this.http.post(`${baseUrl}/trade/position/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  createAlert(formData) {
    return this.http.post(`${baseUrl}/trade/alert/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updateAlert(formData, slug) {
    return this.http.put(`${baseUrl}/trade/alert/${slug}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updatePosition(slug, formData, disableHttpLoader = 'false') {
    return this.http.put(`${baseUrl}/trade/position/${slug}/`, formData, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getAlertData(slug): Observable<any> {
    const qp = new URLSearchParams();
    return this.http.get(`${baseUrl}/trade/alert/${slug}` + qp.toString(), {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getNotes(slug): Observable<any> {
    const qp = new URLSearchParams();
    return this.http.get(`${baseUrl}/trade/notes/${slug}` + qp.toString(), {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  createNote(formData) {
    return this.http.post(`${baseUrl}/trade/notes/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updateNote(formData, slug) {
    return this.http.put(`${baseUrl}/trade/notes/${slug}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  deleteNote(slug) {
    return this.http.delete(`${baseUrl}/trade/notes/${slug}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  deleteLegs(legId, type) {
    return this.http.delete(`${baseUrl}/trade/position/${type}/${legId}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getCountryWiseStock(country): Observable<any> {
    const qp = new URLSearchParams();
    qp.set('country', country);
    return this.http.get(`${baseUrl}/trade/getTickers?` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('cache-request', 'true'),
    });
  }

  getRecentNews(symbol, disableHttpLoader = 'true') {
    const params = new URLSearchParams();
    if (symbol) {
      params.set('symbol', symbol);
    }
    return this.http.get(`${baseUrl}/tools/dashboard/news/list?${params}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getSymbolWiseNotes(symbol, disableHttpLoader = 'true') {
    const params = new URLSearchParams();
    if (symbol) {
      params.set('symbol', symbol);
    }
    return this.http.get(`${baseUrl}/trade/notes?${params}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getLeadSource(page, slug = null, keyword = null, disableHttpLoader = 'true') {
    const qp = new URLSearchParams();
    qp.set('page', page);

    if (slug) {
      qp.set('slug', slug);
    }

    if (keyword) {
      qp.set('keyword', keyword);
    }
    return this.http.get(`${baseUrl}/leadsource?` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getStockDetails(symbol): Observable<any> {
    const qp = new URLSearchParams();
    return this.http.get(`${baseUrl}/tools/dashboard/stock-info/${symbol}?` + qp.toString(), {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  addWatchList(formData) {
    const qp = new URLSearchParams();
    return this.http.post(`${baseUrl}/tools/dashboard/watchList/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
  removeFromWatchList(symbol) {
    return this.http.delete(`${baseUrl}/tools/dashboard/watchList/symbol/${symbol}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  removeWatchList(id) {
    return this.http.delete(`${baseUrl}/tools/dashboard/watchList/${id}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
  getWatchListStockDetails(symbol = null, disableHttpLoader = 'true') {
    const qp = new URLSearchParams();
    if (symbol) {
      qp.set('symbol', symbol.toUpperCase());
    }
    return this.http.get(`${baseUrl}/tools/dashboard/get-all-stock?` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getEarningStockDetails(earning = 'true', disableHttpLoader = 'true') {
    const qp = new URLSearchParams();
    if (earning) {
      qp.set('earning', earning);
    }
    return this.http.get(`${baseUrl}/tools/dashboard/get-all-stock?` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getForWatchList(disableHttpLoader = 'true') {
    const params = new URLSearchParams();
    return this.http.get(`${baseUrl}/tools/dashboard/watchList?${params}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  setContext(value: 'dashboard' | 'watchlist' | 'setup-scanner') {
    this.context = value;
  }

  getContext(): 'dashboard' | 'watchlist' | 'setup-scanner' {
    return this.context;
  }
}
