<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <a (click)="toggleView()"
        ><ion-icon name="chevron-back-outline"></ion-icon>Back to
        {{ showForm ? 'profile' : 'alerts' }}</a
      >
      <ion-button
        fill="outline"
        class=""
        *ngIf="!showForm !== false && !editSlug"
        (click)="toggleView()"
        >Create Alert</ion-button
      >
      <ion-buttons slot="end" *ngIf="showForm !== false && !editSlug">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>
<ng-container *ngIf="showForm">
  <div class="page-heading">
    <h2>Set Alert For {{ data.symbol }}</h2>
  </div>
  <div class="form-container">
    <div class="form-container">
      <form (ngSubmit)="onAlertCreateSubmit()" [formGroup]="tickerAlertForm">
        <div class="input-container">
          <ion-label>Set Alert</ion-label>
          <div class="alert">Alert will be trigger on specified date</div>
          <ion-item class="ion-item-no-inner-padding">
            <ion-select
              lines="none"
              placeholder="Select the option"
              interface="popover"
              [interfaceOptions]="customPopoverOptions"
              formControlName="alertType"
              (ionChange)="selectedOption()"
              [(ngModel)]="alertType"
            >
              <ng-container *ngFor="let type of alertTypes">
                <ion-select-option [value]="type.value">{{ type.label }}</ion-select-option>
              </ng-container>
            </ion-select>
          </ion-item>
        </div>
        <div [ngSwitch]="alertType">
          <div class="input-container" *ngSwitchCase="'on_date'">
            <ion-label>Date & Time</ion-label>
            <ion-item class="ion-item-no-inner-padding">
              <app-alert-date-picker
                [min]="currentDate"
                [feedDate]="tickerAlertForm.value?.triggerDate"
                [hash]="'datetimetickeralertdatetime'"
                [label]="''"
                [options]="datePickerOptions"
                (onDateChange)="onAlertDateChange($event, 'triggerDate')"
              />
            </ion-item>
          </div>
          <div class="input-container" *ngSwitchCase="'option_price'">
            <ion-label>Option Price</ion-label>
            <ion-radio-group
              class="sidebar-radio-group open-price-container"
              formControlName="optionLegType"
            >
              <div class="active">
                <ion-radio color="primary" slot="start" value="short"></ion-radio>
                <ion-label class="ion-padding-end">Short Leg</ion-label>
              </div>
              <div class="active">
                <ion-radio color="primary" slot="start" value="long"></ion-radio>
                <ion-label class="ion-padding-end">Long Leg</ion-label>
              </div>
            </ion-radio-group>

            <ion-label>Set Alert Trigger Price</ion-label>
            <div class="alert">Get alert when stock price match it.</div>
            <ion-item class="ion-item-no-inner-padding">
              $
              <ion-input formControlName="triggerPrice" type="number"></ion-input>
            </ion-item>
          </div>

          <div class="input-container" *ngSwitchCase="'stock_price'">
            <div style="padding: 10px 3px">
              <ion-label>Current Stock Price: </ion-label>
              <ion-label style="margin-left: 16px">${{ data?.stockPrice }}</ion-label>
              <ng-container *ngIf="data.todayPnL > 0">
                <ion-icon name="arrow-up" color="primary" style="font-size: 20px"></ion-icon>
              </ng-container>
              <ng-container *ngIf="data.todayPnL < 0">
                <ion-icon name="arrow-down" color="danger" style="font-size: 20px"></ion-icon>
              </ng-container>
            </div>

            <ion-label>Set Alert Trigger Price</ion-label>
            <div class="alert">Get alert when stock price match it.</div>
            <ion-item class="ion-item-no-inner-padding">
              $
              <ion-input formControlName="triggerPrice" type="number"></ion-input>
            </ion-item>
          </div>
          <div class="input-container" *ngSwitchCase="'stock_price'">
            <ion-label>Select Condition</ion-label>
            <ion-item class="ion-item-no-inner-padding">
              <ion-select
                lines="none"
                placeholder="Select the Condition"
                interface="popover"
                [interfaceOptions]="customPopoverOptions"
                formControlName="conditionType"
              >
                <ng-container *ngFor="let type of conditionTypes">
                  <ion-select-option [value]="type.value">{{ type.label }}</ion-select-option>
                </ng-container>
              </ion-select>
            </ion-item>
          </div>
          <div class="input-container" *ngSwitchCase="'stock_price'">
            <ion-label>Expiration Date</ion-label>
            <ion-item class="ion-item-no-inner-padding">
              <ion-input
                formControlName="expirationDate"
                readonly="true"
                (click)="openDatePicker()"
                (focus)="openDatePicker()"
                placeholder="MM/DD/YYYY"
              ></ion-input>
              <ion-datetime
                presentation="date"
                (ionChange)="closeDatePicker($event)"
                [hidden]="!showDatePicker"
                displayFormat="MM/DD/YYYY"
                pickerFormat="MM DD YYYY"
              ></ion-datetime>
            </ion-item>
            <div *ngIf="tickerAlertForm.get('expirationDate').errors?.invalidDate" class="error">
              Please select the valid date.
            </div>
          </div>
        </div>
        <div class="input-container" *ngIf="alertType">
          <label style="font-size: 18px" for="notes">Message</label>
          <textarea
            class="message-description"
            formControlName="message"
            id="message"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <div class="input-container" *ngIf="alertType">
          <div class="active">
            <label>Active</label>
            <ion-toggle formControlName="status"></ion-toggle>
          </div>
        </div>
        <ion-button
          type="submit"
          [disabled]="tickerAlertForm.invalid"
          color="primary"
          fill="solid"
          style="width: 100%"
        >
          {{ editSlug ? 'Update' : 'Create' }} Alert
        </ion-button>
      </form>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showForm">
  <div class="page-heading">
    <h2>{{ data.symbol }} Alerts</h2>
  </div>
  <ion-segment [(ngModel)]="selectedSegment">
    <ion-segment-button (click)="getAlertList()" value="active">
      <ion-label>Active</ion-label>
    </ion-segment-button>
    <ion-segment-button (click)="getAlertList()" value="inactive">
      <ion-label>Inactive</ion-label>
    </ion-segment-button>
  </ion-segment>
  <ng-container *ngIf="selectedSegment === 'active'">
    <div *ngFor="let list of activeAlerts" class="list-container">
      <div *ngIf="list.type != 'on_date'">
        <div class="list-data list-data-custom">
          <div class="symbol">
            <ion-icon name="bar-chart-outline"></ion-icon>
          </div>
          <div class="right-data-block">
            <div class="title">Stock Price Alert</div>
            <div class="alert-info">
              Alert will be triggered when price reaches {{ list.triggerPrice | currency }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="list.type === 'on_date'">
        <div class="list-data list-data-custom">
          <div class="symbol">
            <ion-icon name="time-outline"></ion-icon>
          </div>
          <div class="right-data-block">
            <div class="title">Date/Timer Alert</div>
            <div class="alert-info">
              Alert will be triggered on reaches {{ list.triggerDate | date : 'medium' }}
            </div>
          </div>
        </div>
      </div>
      <div class="edit">
        <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
          <ion-icon name="create-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div *ngIf="activeAlerts?.length === 0" class="empty-alert-list">
      <ion-icon name="notifications-off-outline"></ion-icon>
      <span>You don't have any alerts</span>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedSegment === 'inactive'">
    <div *ngFor="let list of inactiveAlerts" class="list-container">
      <div *ngIf="list.type != 'on_date'">
        <div class="list-data list-data-custom">
          <div class="symbol">
            <ion-icon name="bar-chart-outline"></ion-icon>
          </div>

          <div class="right-data-block">
            <div class="title">Stock Price Alert</div>
            <div class="alert-info">
              Alert will be triggered when price reaches {{ list.triggerPrice | currency }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="list.type === 'on_date'">
        <div class="list-data list-data-custom">
          <div class="symbol">
            <ion-icon name="time-outline"></ion-icon>
          </div>
          <div class="right-data-block">
            <div class="title">Date/Timer Alert</div>
            <div class="alert-info">
              Alert will be triggered on reaches {{ list.triggerDate | date : 'medium' }}
            </div>
          </div>
        </div>
      </div>
      <div class="edit">
        <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
          <ion-icon name="create-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div *ngIf="inactiveAlerts?.length === 0" class="empty-alert-list">
      <ion-icon name="notifications-off-outline"></ion-icon>
      <span>You don't have any alerts</span>
    </div>
  </ng-container>
</ng-container>
