<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ion-row>
      <ion-col size="8">
        <div>
          <ol class="cd-breadcrumb custom-separator custom-icons">
            <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
            <li>
              <a routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}">Setup-Scanner</a>
            </li>
          </ol>
        </div>
      </ion-col>
    </ion-row>

    <div *ngIf="!showDatatable">
      <div class="page-heading">
        <h2>Setup Scanner</h2>
      </div>

      <ng-container slides-per-view="1" speed="500" pagination="true" class="step-scanner">
        <ion-slide class="step-scanner">
          <ion-grid class="icon-grid-block">
            <ion-row>
              <ion-col size="4" *ngFor="let row of staticData">
                <ion-card>
                  <ion-card-header>
                    <img class="setup-img" [src]="row.image" />
                    <div class="custom-css">
                      <div class="custom-css-content">
                        <span class="primium-watchlist setupname-css">{{ row.name }}</span>
                        <p class="font-css">{{ row.description }}</p>
                      </div>
                      <div *ngIf="!row.description" class="Under-construction">
                        <div>
                          <ion-icon name="construct-outline"></ion-icon>
                          <h1>Under construction</h1>
                        </div>
                      </div>
                      <span
                        *ngIf="row.description"
                        class="primium-watchList font-css1"
                        routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}/product/{{
                          row.name
                        }}"
                      >
                        View
                      </span>
                    </div>
                  </ion-card-header>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
      </ng-container>
    </div>
  </div>
</ion-content>
