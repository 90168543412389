import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Input() notesData: any;
  showForm = false;
  public tickerNotesForm: FormGroup;
  editSlug: string;
  noteData: any;
  constructor(
    private formBuilder: FormBuilder,
    private toolService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController
  ) {
    this.tickerNotesForm = this.formBuilder.group({
      description: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getNotesList();
  }

  getNotesList() {
    let slug = this.notesData.slug;
    this.toolService.getNotes(slug).subscribe((res) => {
      this.noteData =
        res && res?.data && res.data.NotesList && res.data.NotesList.length
          ? res.data.NotesList
          : [];
    });
  }

  onNotesCreateSubmit() {
    const description = this.tickerNotesForm.get('description').value;
    const status = 'active';
    const slug = this.notesData.slug;
    const payload = {
      tickerSlug: slug,
      status: status,
      description: description,
    };
    if (!this.editSlug) {
      this.toolService.createNote(payload).subscribe((res: any) => {
        if (res) {
          this.tickerNotesForm.reset();
          this.showForm = false;
          this.toastServ.presentToast(res?.message, 'success');
          this.getNotesList();
        }
      });
    } else {
      this.toolService.updateNote(payload, this.editSlug).subscribe((res: any) => {
        if (res) {
          this.tickerNotesForm.reset();
          this.showForm = false;
          this.toastServ.presentToast(res?.message, 'success');
          this.getNotesList();
        }
      });
    }
  }

  toggleView(noteDetail?: any) {
    this.showForm = !this.showForm;
    this.editSlug = undefined;
    if (noteDetail) {
      this.editSlug = noteDetail.slug;
      this.tickerNotesForm.patchValue({
        description: noteDetail.description,
        status: 'active',
      });
    } else {
      this.tickerNotesForm.reset();
    }
  }

  deleteNotes(noteDetail) {
    this.toastServ
      .confirmationAlert('Are you sure you want to delete this note?', 'Delete')
      .then((confirm) => {
        if (confirm) {
          this.toolService.deleteNote(noteDetail.slug).subscribe((response: any) => {
            this.toastServ.presentToast(response.message, 'success');
            this.getNotesList();
          });
        }
      });
  }

  cancel() {
    this.menuController.close();
  }
}
